import React, { useEffect, useState } from "react";
import axiosBaseURL from "../utils/axios";
import { Loader, PageBanner } from "../components";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const Donors = () => {
  const [loading, setLoading] = useState(false);
  const [donorsData, setDonorsData] = useState([]);

  // Fetch Donors
  const fetchDonors = async () => {
    setLoading(true);
    try {
      await axiosBaseURL.get(`/donor-lists`).then((res) => {
        if (res?.data?.data?.length > 0) {
          setLoading(false);
          setDonorsData(res.data.data);
          return;
        }
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDonors();
  }, []);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Donors List</title>
      </Helmet>
      <PageBanner title="Blood Donors In Nepal" />
      <div className="section-padding">
        <div className="max-w-[1000px] mx-auto pt-5 pb-16 ">
          <div className="w-full flex flex-col lg:flex-row gap-5">
            {/*....Donors List...*/}
            <div className="my-[20px]  flex-grow">
              <p className="font-bold heading !text-3xl text-left text-[#00539B]">
                Donors List
              </p>

              {loading ? (
                <div className="w-full flex justify-center items-center">
                  <Loader />
                </div>
              ) : donorsData?.length > 0 ? (
                <div className="w-full overflow-x-scroll flex lg:items-center lg:justify-center mt-[30px] scrollbar-hide">
                  <table className="w-full">
                    <thead className="bg-[#D42F32] text-white">
                      <tr>
                        <th>Group</th>
                        <th>Name</th>
                        <th>Address</th>
                        <th>Contact No</th>
                        <th>Gmail Id</th>
                      </tr>
                    </thead>

                    <tbody className="text-[#777777] scrollbar-hide">
                      {console.log(donorsData)}
                      {donorsData?.map((item, index) => (
                        <tr key={index}>
                          <td>{item?.bloodGroup?.name}</td>
                          <td>{item?.name}</td>
                          <td>{item?.address}</td>
                          <td>{item?.phone}</td>
                          <td>{item?.email}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div>
                  <p>No Data Found</p>
                </div>
              )}
            </div>

            {/*...Links....*/}
            <div className="w-full lg:w-[150px] lg:mt-[100px] flex flex-col items-center gap-[20px] my-[30px] ">
              <Link
                to="/find-donor"
                className="bg-[#D42F32] w-full px-[20px] py-[10px] text-white rounded-lg shadow-lg "
              >
                Find Donors
              </Link>

              <Link
                to="/volunteer-with-us"
                className="bg-[#D42F32] w-full px-[20px] py-[10px] text-white rounded-lg shadow-lg "
              >
                Volunteer with us
              </Link>

              <Link
                to="/register-as-donor"
                className="bg-[#D42F32] w-full px-[20px] py-[10px] text-white rounded-lg shadow-lg "
              >
                Register as Donor
              </Link>

              <Link
                to="/host-blood-drive"
                className="bg-[#D42F32] w-full px-[20px] py-[10px] text-white rounded-lg shadow-lg "
              >
                Host Blood Drive
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Donors;
