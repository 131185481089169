export const navLinks = [
  {
    title: "Home",
    link: "/",
    subCategory: [],
  },
  {
    title: "About Us",
    link: "/about-us",
    subCategory: [],
  },
  {
    title: "Need Blood",
    link: "/find-donor",
    subCategory: [],
  },
  {
    title: "News",
    link: "/news",
    subCategory: [],
  },
  // {
  //   title: "Events",
  //   link: "/events",
  //   subCategory: [],
  // },
  {
    title: "Academic Research",
    link: "",
    subCategory: [
      {
        title: "Research",
        link: "/research",
      },
      {
        title: "Blood Group Info",
        link: "/blood-center",
      },
    ],
  },
  {
    title: "Donors",
    link: "/donors",
    subCategory: [],
  },
];
