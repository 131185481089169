import React, { useEffect, useState } from "react";
import { Loader, PageBanner } from "../components";
import { prefferedSectorToVoolunteer } from "../data/prefferedSectorToVolunteerData";
import axiosBaseURL from "../utils/axios";
import { Helmet } from "react-helmet";

const VolunteerWithUs = () => {
  const [selectedSectorToVolunteer, setSelectedSectorToVolunteer] = useState(
    []
  );
  const [iAgree, setIAgree] = useState(false);

  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState("");
  const [permanentAddress, setPermanentAddress] = useState("");
  const [temporaryAddress, setTemporaryAddress] = useState("");
  const [skills, setSkills] = useState("");
  const [sector, setSector] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [volunteerRequirementDetails, setVolunteerRequirementDetails] =
    useState();
  // Handle Checkbox
  const handleCheckBox = (id) => {
    if (
      selectedSectorToVolunteer?.length > 0 &&
      selectedSectorToVolunteer.includes(id)
    ) {
      const filteredData = selectedSectorToVolunteer?.filter(
        (item) => item !== id
      );
      setSelectedSectorToVolunteer(filteredData);
      return;
    } else if (!selectedSectorToVolunteer.includes(id)) {
      const filteredData = [...selectedSectorToVolunteer, id];
      setSelectedSectorToVolunteer(filteredData);
      return;
    }
  };

  // Fetching Preferred sector
  const fetchSector = async () => {
    try {
      await axiosBaseURL.get("/sector").then((res) => {
        if (res?.data?.data?.sector.length > 0) {
          setSector(res?.data?.data?.sector);
          return;
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  // Fetching Volunteer Requirements Details
  const fetchVolunteerRequirementDetails = async () => {
    try {
      await axiosBaseURL.get("/volunteer-page").then((res) => {
        if (res?.data?.data) {
          setVolunteerRequirementDetails(res?.data?.data);
          return;
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  console.log(volunteerRequirementDetails);
  useEffect(() => {
    fetchSector();
    fetchVolunteerRequirementDetails();
  }, []);

  // Handle I agree checkbox
  const handleIAgree = () => {
    setIAgree((prev) => !prev);
  };

  // Handle Form Submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      fullName &&
      phone &&
      email &&
      temporaryAddress &&
      skills &&
      selectedSectorToVolunteer
    ) {
      if (phone.length !== 10) {
        setErrorMessage("Length of Contact number must be 10.");
        return;
      } else if (!iAgree) {
        setErrorMessage("You have to Agree with our policy. Click on I Agree!");
      } else {
        const formData = {
          name: fullName,
          phone: phone,
          email: email,
          address: temporaryAddress,
          permanent_address: permanentAddress,
          skills: skills,
          sector: selectedSectorToVolunteer,
          role: "volunteer",
        };

        try {
          setLoading(true);
          await axiosBaseURL
            .post("/register/volunteer", formData)
            .then((res) => {
              setLoading(false);
              setSuccessMessage(res.data.message);
              setErrorMessage("");
            });
        } catch (error) {
          console.log(error);
          setLoading(false);
          setSuccessMessage("");
          setErrorMessage(error.message);
        }
      }
    } else {
      setErrorMessage("Fill All the fields");
    }
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Volunteer with Us</title>
        <meta name="description" content="" />
      </Helmet>
      <PageBanner title="Volunteer with us" />
      <div className="section-padding">
        <div className="mx-auto max-w-[1600px] py-[20px]">
          <div className="text-start">
            {volunteerRequirementDetails && (
              <>
                <p className="text-[#00539B] font-bold ">
                  {volunteerRequirementDetails?.volunteer_title}
                </p>
                <div
                  className="mt-[20px] text-[#777777]"
                  dangerouslySetInnerHTML={{
                    __html: volunteerRequirementDetails.volunteer_details,
                  }}
                />
              </>
            )}
          </div>

          <div className="mx-auto max-w-[1600px] flex flex-col lg:flex-row gap-[40px] mt-[20px]">
            {/*...Contact Information Form....*/}
            <div className="w-full lg:w-[50%] bg-[#F5F5F5] px-[10px] py-[20px] ">
              <p className="text-start font-bold">Contact Information</p>

              <form
                onSubmit={handleSubmit}
                className="w-full mt-[30px] px-[10px] flex flex-col"
              >
                <div className="w-full lg:w-[80%] flex flex-col gap-[20px]">
                  <label className="flex flex-col items-start gap-[5px]">
                    <p className="font-bold">Full Name</p>
                    <input
                      type="text"
                      placeholder="Enter Your Name"
                      onChange={(e) => setFullName(e.target.value)}
                      className="w-full border border-slate-300 py-[7px] px-[5px] rounded-[6px]"
                    />
                  </label>

                  <label className="flex flex-col items-start gap-[5px]">
                    <p className="font-bold">Phone</p>
                    <input
                      type="number"
                      placeholder="Enter Your Phone Number"
                      onChange={(e) => setPhone(e.target.value)}
                      className="w-full border border-slate-300 py-[7px] px-[5px] rounded-[6px]"
                    />
                  </label>

                  <label className="flex flex-col items-start gap-[5px]">
                    <p className="font-bold">Email</p>
                    <input
                      type="email"
                      placeholder="Enter Your Email"
                      onChange={(e) => setEmail(e.target.value)}
                      className="w-full border border-slate-300 py-[7px] px-[5px] rounded-[6px]"
                    />
                  </label>

                  <label className="flex flex-col items-start gap-[5px]">
                    <p className="font-bold">Permanent address</p>
                    <input
                      type="text"
                      placeholder="Enter Your Address"
                      onChange={(e) => setPermanentAddress(e.target.value)}
                      className="w-full border border-slate-300 py-[7px] px-[5px] rounded-[6px]"
                    />
                  </label>

                  <label className="flex flex-col items-start gap-[5px]">
                    <p className="font-bold">Temporary address </p>
                    <input
                      type="text"
                      placeholder="Enter Your Address"
                      onChange={(e) => setTemporaryAddress(e.target.value)}
                      className="w-full border border-slate-300 py-[7px] px-[5px] rounded-[6px]"
                    />
                  </label>

                  <label className="flex flex-col items-start gap-[5px]">
                    <p className="font-bold">Skills</p>
                    <textarea
                      placeholder="Enter any relevant qualifications, skills, or interests."
                      onChange={(e) => setSkills(e.target.value)}
                      className="w-full border border-slate-300 py-[7px] px-[5px] rounded-[6px]"
                    />
                  </label>
                </div>

                {/*....Prefered sector to volunteer....*/}
                <div className="w-[80%] mt-[30px]">
                  <p className="font-bold text-start">
                    Preferred Sector to volunteer
                  </p>

                  <div className="w-full flex items-start justify-between gap-[20px] flex-wrap pl-[30px] py-[20px]">
                    {sector?.map((item, index) => (
                      <div
                        key={index}
                        className="w-[200px] flex items-start gap-[5px] text-start "
                      >
                        <input
                          type="checkbox"
                          id={item.name}
                          name={item.name}
                          value={item.name}
                          checked={
                            selectedSectorToVolunteer.includes(item.name)
                              ? true
                              : false
                          }
                          onChange={(e) => handleCheckBox(e.target.value)}
                        />
                        <label htmlFor={item.name} className="mt-[-7px]">
                          {item.display_name}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>

                <hr className="h-[5px]" />

                {/*...I Agree Section....*/}
                <div className="mt-[30px] flex flex-col text-start gap-[10px]">
                  <p>
                    I am aware and agree that submitting this application does
                    not automatically sign me up as a volunteer with TU Lions
                    Blood Bank, and that there may be requirements I must
                    fulfill, including accepting specified volunteer policies
                    and procedures, before I begin volunteering. I certify that
                    the data I have entered on this form is true and accurate.
                  </p>

                  <div className="w-[200px] flex items-start gap-[5px] text-start font-medium ">
                    <input
                      type="checkbox"
                      id="i_agree"
                      name="i_agree"
                      value="I Agree"
                      checked={iAgree ? true : false}
                      onChange={() => handleIAgree()}
                      className="border-2"
                    />
                    <label htmlFor={``} className="mt-[-7px]">
                      I Agree
                    </label>
                  </div>
                </div>
                {/*....Submit Button...*/}
                {errorMessage && (
                  <div className="py-[10px]">
                    <p className="text-red-500 text-start">*{errorMessage}</p>
                  </div>
                )}

                {loading && !successMessage ? (
                  <Loader />
                ) : !loading && successMessage ? (
                  <div>
                    <p className="text-green-400 bg-white px-[10px] py-[6px] rounded-xl shadow-xl">
                      {successMessage}
                    </p>
                  </div>
                ) : (
                  <button
                    type="submit"
                    className="w-max bg-[#D42F32] px-[20px] py-[7px] text-white rounded-lg mt-[20px]"
                  >
                    Submit
                  </button>
                )}
              </form>
            </div>
            {/*....Volunteer Requirements....*/}
            {volunteerRequirementDetails && (
              <div className="max-w-[450px] w-full text-start">
                {/* <p className="font-bold text-[#00539B] text-lg text-center">
                {volunteerRequirementDetails?.volunteer_title}
              </p> */}
                <div className="flex flex-col gap-[20px]">
                  {/* <div
                  className="mt-[20px] text-[#777777]"
                  dangerouslySetInnerHTML={{
                    __html: volunteerRequirementDetails.volunteer_details,
                  }}
                /> */}
                  <div>
                    <img
                      src={volunteerRequirementDetails.volunteer_image}
                      alt="volunteerImage"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VolunteerWithUs;
