import React from "react";
import { BsArrowRightShort } from "react-icons/bs";

const DiscoverHowToDonate = () => {
  const donateOptions = [
    {
      title: "Financial donation",
      link: "",
    },
    {
      title: "Donate as a partner",
      link: "",
    },
    {
      title: "Information on Recognition Program",
      link: "",
    },
    {
      title: "Volunteering",
      link: "",
    },
    {
      title: "See all",
      link: "",
    },
  ];
  return (
    <div className="section-padding">
      <div className="max-w-[1400px] mx-auto py-16">
        <div className="grid md:grid-cols-2 col-span-1 lg:gap-16 gap-8">
          <div className="col-span-1">
            <div className="text-start w-full lg:w-[600px] flex flex-col gap-[20px] ">
              <p className="font-bold text-2xl">
                Discover How you can Donate Blood ?
              </p>
              <p className="text-[#FFD0D0] leading-8">
                There are many ways to donate. You can give one-time or
                recurring financial donations, organize group donations in
                collaboration with other organizations or communities, sponsor a
                donation event or even volunteer your time.
              </p>
            </div>
          </div>
          <div className="col-span-1 md:justify-self-end">
            {/* <div className="inline-block ml-auto"> */}
            <div className="flex flex-col gap-[10px] ">
              {donateOptions?.map((item, index) => (
                <div key={index} className="flex items-center gap-[10px]">
                  <BsArrowRightShort className="text-4xl" />
                  <p className="underline underline-white underline-offset-4 text-[#FFFCFC] text-left">
                    {item.title}
                  </p>
                </div>
              ))}
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiscoverHowToDonate;
