import React from "react";
import { RxCross2 } from "react-icons/rx";

const Popup = ({ data, handleCross }) => {
  return (
    <div style={{
      zIndex: 99
    }} className="fixed w-[95%] lg:w-[600px] top-[50%] left-[50%] transform -translate-x-[50%] -translate-y-[50%] bg-white rounded-xl ">
      <div className="">
        <div className="relative w-full px-[20px] py-[10px] border-2 border-b-slate-300 border-t-0 border-l-0 border-r-0 ">
          <p className="text-center">{data.title}</p>
          <div onClick={handleCross} className="absolute right-0 top-0 cursor-pointer w-[30px] h-[30px] rounded-full flex justify-center items-center shadow-lg ">
            <RxCross2 />
          </div>
        </div>
        <img src={data.image} className="w-full h-[400px] lg:h-[500px] object-contain" alt="popUpImage" />
      </div>
    </div>
  );
};

export default Popup;
