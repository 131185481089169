import React, { useEffect, useState } from "react";
import { HeroBanner, Loader, NewsCard, PageBanner } from "../components";
import { newsAndEvents } from "../data/newsAndEvents";
import axiosBaseURL from "../utils/axios";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { IoCalendarOutline } from "react-icons/io5";
import parse from "html-react-parser";
import { lions_logo, logo } from "../assets";

const News = () => {
  const [loading, setLoading] = useState(false);
  const [news, setNews] = useState([]);
  const [events, setEvents] = useState([]);

  const fetchNews = async () => {
    setLoading(true);
    try {
      await axiosBaseURL.get("/blogs").then((res) => {
        if (res?.data?.data?.length > 0) {
          setNews(res?.data?.data);
          setLoading(false);
          return;
        }
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const fetchEvents = async () => {
    setLoading(true);
    try {
      await axiosBaseURL.get("/events").then((res) => {
        if (res?.data?.data?.length > 0) {
          setEvents(res?.data?.data);
          // console.log(events);
          setLoading(false);
          return;
        }
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNews();
    fetchEvents();
  }, []);
  return (
    <div className="pb-[60px]">
      <Helmet>
        <meta charSet="utf-8" />
        <title>News & Events</title>
        <meta name="description" content="" />
      </Helmet>
      <PageBanner title="News & Events" />

      {/* <div className="px-[20px] lg:px-[100px] w-full mt-[40px]">
        <p className="font-roboto font-extrabold text-2xl text-center text-[#00539B]">
          News
        </p>

        <div className="w-full flex flex-col lg:flex-row items-center justify-center flex-wrap gap-[40px] mt-[30px] scrollbar-hide">
          {loading ? (
            <div className="w-full flex justify-center items-center">
              <Loader />
            </div>
          ) : news?.length > 0 ? (
            news?.map((item, index) => (
              <NewsCard
                key={index}
                type="news"
                newsTitle={item.title}
                description={item.excerpt}
                date={item.updated_at}
                image={item.image}
                slug={item.slug}
              />
            ))
          ) : (
            <div>No Data Found</div>
          )}
        </div>
      </div> */}
      <div className="news-section text-left section-padding">
        <div className="mx-auto max-w-[1600px] py-10 ">
          <div className="grid grid-cols-5 gap-5">
            <div className="xl:col-span-4 col-span-full">
              <div className="news-wrapper">
                <div className="heading-wrapper">
                  <h3 className="heading">News</h3>
                </div>
                <ul className="grid lg:grid-cols-2 grid-cols-1 gap-5">
                  {news?.slice(0, 8).map((item, index) => (
                    <li
                      className="col-span-1 p-4 border border-solid border-gray rounded-xl"
                      key={index}
                    >
                      <div className="news-box flex items-start gap-5 min-h-[212px] md:flex-nowrap flex-wrap">
                        <figure className="overflow-hidden rounded-xl md:h-[212px] md:w-[200px] h-[200px]  w-full flex-none">
                          <img
                            src={item?.image ? item?.image : lions_logo}
                            alt={item?.title}
                            className="h-full w-full object-cover object-center"
                          />
                        </figure>
                        <article>
                          <h2 className="font-semibold text-xl line-clamp-2 mb-1">
                            {item?.title}
                          </h2>
                          <span className="flex gap-2 items-center text-gray-500 mb-3 text-sm">
                            <IoCalendarOutline className="text-primary" />
                            {item?.created_at}
                          </span>
                          <div className="line-clamp-3 mb-2">
                            {item?.details && parse(item?.details)}
                          </div>
                          <Link
                            to={`/blogs/${item?.slug}`}
                            className="btn-primary"
                          >
                            View Details
                          </Link>
                        </article>
                      </div>
                    </li>
                  ))}
                </ul>
                {news?.length > 8 && (
                  <>
                    <div className="btn-wrapper text-center w-full pt-8">
                      <Link to="/blogs" className="btn-transparent">
                        View More
                      </Link>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="xl:col-span-1 col-span-full">
              <div className="blog-wrapper">
                <div className="heading-wrapper">
                  <h3 className="heading">Events</h3>
                </div>
                <div className="xl:block grid md:grid-cols-2 grid-cols-1 gap-6 mb-8">
                  {events?.slice(0, 2).map((item, index) => (
                    <div className="col-span-1" key={index}>
                      <div
                        className="latest-blog rounded-xl overflow-hidden [&:not(:last-child)]:mb-8 h-full  border border-solid border-gray "
                        
                      >
                        <figure className="h-[220px] bg-slate-500">
                          <img
                            src={item?.image ? item?.image : lions_logo}
                            alt={item?.title}
                            className="h-full w-full object-cover object-center"
                          />
                        </figure>
                        <article className="p-4">
                          <h2 className="font-semibold text-xl line-clamp-2 mb-2">
                            {item?.title}
                          </h2>
                          <span className="flex gap-2 items-center text-gray-500 mb-3 text-sm">
                            <IoCalendarOutline className="text-primary" />{" "}
                            {item?.date}
                          </span>
                          <div className="line-clamp-2">
                            {item?.details && parse(item?.details)}
                          </div>
                          <Link
                            to={`/event-details/${item?.slug}`}
                            className="text-primary hover:underline"
                          >
                            read more
                          </Link>
                        </article>
                      </div>
                    </div>
                  ))}
                </div>
                {news?.length > 2 && (
                  <>
                    <div className="btn-wrapper text-center w-full">
                      <Link to="/events" className="btn-transparent">
                        View More
                      </Link>
                    </div>
                  </>
                )}
              </div>
              {/* <div className="download-wrapper">
                <div className="heading-wrapper">
                  <h3 className="heading">Downloads</h3>
                </div>
                <div className="download-box bg-primary rounded-xl p-4 text-white">
                  <h4 className="font-medium mb-2">
                    Necessary Filies are available here
                  </h4>
                  <Link className="btn-primary !bg-white !text-primary !font-medium">
                    Go to Download Page
                  </Link>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default News;
