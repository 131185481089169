import React, { useEffect, useState } from "react";
import { Dropdown, Loader, PageBanner } from "../components";
import axiosBaseURL from "../utils/axios";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

const FindDonor = () => {
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedPlaces, setSelectedPlaces] = useState("");
  const [placesData, setPlacesData] = useState([]);
  const [selectedBloodGroup, setSelectedBloodGroup] = useState("");
  const [loading, setLoading] = useState(false);
  const [districts, setDistricts] = useState([]);
  const [bloodGroupTypes, setBloodGroupTypes] = useState([]);
  const [donorsData, setDonorsData] = useState([]);
  const [formSubmitionStarted, setFormSubmitionStarted] = useState(false);
  const [dataDonor, setDataDonor] = useState();

  // Handle District Chnage
  const handleDistrictChange = (event) => {
    setSelectedDistrict(event.target.value);
  };

  // Handle place change
  const handlePlaceChange = (event) => {
    setSelectedPlaces(event.target.value);
  };

  // Handle Blood Group change
  const handleBloodGroupChange = (event) => {
    setSelectedBloodGroup(event.target.value);
  };

  useEffect(() => {
    if (selectedDistrict && placesData?.length > 0) {
      const filteredDistrict = placesData?.filter(
        (item) => item.district_id === selectedDistrict
      );
      if (filteredDistrict?.length > 0) {
        setPlacesData(filteredDistrict);
      }
    }
  }, [selectedDistrict]);

  // Fetch District
  const fetchDistrict = async () => {
    setLoading(true);
    try {
      await axiosBaseURL.get(`/districts`).then((res) => {
        if (res?.data?.data?.length > 0) {
          setDistricts(res?.data?.data);
          setLoading(false);

          return;
        }
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // Fetch Locations
  // const fetchLocations = async () => {
  //   setLoading(true);
  //   try {
  //     await axiosBaseURL.get(`/locations`).then((res) => {
  //       if (res?.data?.data?.length > 0) {
  //         setPlacesData(res?.data?.data);
  //         setLoading(false);
  //         return;
  //       }
  //       setLoading(false);
  //     });
  //   } catch (error) {
  //     console.log(error);
  //     setLoading(false);
  //   }
  // };

  // Fetch Blood Types
  const fetchBloodTypes = async () => {
    setLoading(true);
    try {
      await axiosBaseURL.get(`/blood-groups`).then((res) => {
        if (res?.data?.data?.length > 0) {
          setBloodGroupTypes(res?.data?.data);
          setLoading(false);
          // console.log(bloodGroupTypes);
          return;
        }
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const fetchData = async () => {
    try {
      await axiosBaseURL.get(`/donor-page`).then((res) => {
        if (res?.data) {
          setDataDonor(res?.data);
          return;
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchDistrict();
    // fetchLocations();
    fetchBloodTypes();
    fetchData();
  }, []);

  // Handle Submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitionStarted(true);

    // if (selectedBloodGroup && selectedDistrict && selectedPlaces) {
    //   setLoading(true);
    //   try {
    //     await axiosBaseURL
    //       .get(
    //         `search/donor-lists/${selectedBloodGroup}/${selectedDistrict}/${selectedPlaces}`
    //       )
    //       .then((res) => {
    //         if (res?.data?.data?.length > 0) {
    //           setLoading(false);
    //           setDonorsData(res.data.data);
    //           return;
    //         }
    //         setLoading(false);
    //       });
    //   } catch (error) {
    //     console.log(error);
    //     setLoading(false);
    //   }
    //   return;
    // }
    // if (selectedBloodGroup && selectedDistrict) {
    //   setLoading(true);
    //   try {
    //     await axiosBaseURL
    //       .get(`search/donor-lists/${selectedBloodGroup}/${selectedDistrict}`)
    //       .then((res) => {
    //         if (res?.data?.data?.length > 0) {
    //           setLoading(false);
    //           setDonorsData(res.data.data);
    //           return;
    //         }
    //         setLoading(false);
    //       });
    //   } catch (error) {
    //     console.log(error);
    //     setLoading(false);
    //   }
    //   return;
    // }
    // if (selectedBloodGroup && !selectedDistrict && !selectedPlaces) {
    //   setLoading(true);
    //   try {
    //     await axiosBaseURL
    //       .get(`search/donor-lists/${selectedBloodGroup}`)
    //       .then((res) => {
    //         if (res?.data?.data?.length > 0) {
    //           setLoading(false);
    //           setDonorsData(res.data.data);
    //           return;
    //         }
    //         setLoading(false);
    //       });
    //   } catch (error) {
    //     console.log(error);
    //     setLoading(false);
    //   }
    //   return;
    // }
    if (selectedBloodGroup || selectedDistrict || selectedPlaces) {
      setLoading(true);

      // Build the API endpoint based on the selected parameters
      let endpoint = "";
      if (selectedBloodGroup) {
        endpoint += `/${selectedBloodGroup}`;
      }
      if (selectedDistrict) {
        endpoint += `/${selectedDistrict}`;
      }
      if (selectedPlaces) {
        endpoint += `/${selectedPlaces}`;
      }
      console.log(endpoint);
      try {
        await axiosBaseURL
          .get(`search/donor-lists${endpoint}`)
          .then((res) => {
            if (res?.data?.data?.length > 0) {
              setLoading(false);
              setDonorsData(res.data.data);
              return;
            } else {
              setLoading(false);
              setDonorsData([]);
            }
          })
          .catch((error) => {
            console.log("Error:", error);
            setLoading(false);
            setDonorsData([]); // Reset donorsData on error
          });
      } catch (error) {
        console.log("Error:", error);
        setLoading(false);
        setDonorsData([]); // Reset donorsData on error
      }
    }
  };
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Find Blood Donors</title>
        <meta name="description" content="" />
      </Helmet>
      <PageBanner title="Find Donors" />
      <div className="section-padding">
        <div className="max-w-[1000px] mx-auto pt-16 ">
          <div className="find-wrapper text-left">
            <div className="heading-wrapper">
              <h4 className="heading !text-3xl">Find Donors</h4>
            </div>
            <div className="find-desc leading-8 mb-6 text-justify">
              {dataDonor?.need_blood_excerpt &&
                parse(dataDonor?.need_blood_excerpt)}
            </div>
          </div>
          <div className="w-full flex flex-col lg:flex-row justify-between gap-5">
            {/*...Find Donors....*/}
            <div className="flex justify-center items-center flex-grow">
              <div className="w-full flex flex-col items-center  lg:mb-[80px]">
                {/*...Find Donor title...*/}
                {/* <div className="w-full py-[40px] border border-x-0 border-t-0 border-b-[#CC8282]">
                <p className="text-[#D42F32] text-lg font-bold">Find Donor</p>
              </div> */}

                {/*...Search Donor Field....*/}
                <div className="w-full mt-[30px]">
                  <div className="w-full bg-[#D42F32] text-center py-[8px] text-white rounded-t-[8px]">
                    <p>Search Blood Donor</p>
                  </div>

                  <form
                    onSubmit={handleSubmit}
                    className="w-full flex flex-col border border-[#FFAEAE] rounded-b-[8px] mt-[5px] py-[30px] px-[30px] shadow-lg "
                  >
                    <div className="w-full flex items-center justify-between flex-wrap gap-[30px]">
                      <Dropdown
                        dropdownTitle="Blood Group"
                        style={`md:w-[200px] w-full`}
                        data={bloodGroupTypes}
                        selectedData={selectedBloodGroup}
                        onChange={handleBloodGroupChange}
                      />
                      <Dropdown
                        dropdownTitle="Select District"
                        style={`md:w-[200px] w-full`}
                        data={districts}
                        selectedData={selectedDistrict}
                        onChange={handleDistrictChange}
                      />

                      <input
                        type="text"
                        className={` md:w-[200px] w-full
                    py-[7px] border-2 border-[#777777] rounded-[4px] px-[5px] `}
                        onChange={handlePlaceChange}
                        placeholder="Select Place"
                      />
                      {/* <Dropdown
                    dropdownTitle="Select Place"
                    isWidthSpecified={true}
                    data={placesData}
                    selectedData={selectedPlaces}
                    onChange={handlePlaceChange}
                  /> */}
                    </div>

                    <button
                      type="submit"
                      className="w-max bg-[#D42F32] px-[20px] py-[7px] text-white rounded-lg mt-[30px]"
                    >
                      Submit
                    </button>
                  </form>
                </div>

                {/*...Found Donor....*/}
                {formSubmitionStarted && (
                  <div className="w-full  overflow-x-scroll scrollbar-hide flex flex-col lg:items-center lg:justify-center mt-[30px]">
                    {loading ? (
                      <div className="w-full flex justify-center items-center">
                        <Loader />
                      </div>
                    ) : donorsData?.length > 0 ? (
                      <div className="w-full overflow-x-scroll scrollbar-hide flex flex-col lg:items-center lg:justify-center mt-[30px]">
                        <p className="text-[#00539B] font-bold py-[6px]">
                          Found Donor's List
                        </p>
                        <table className="w-full">
                          <thead className="bg-[#D42F32] text-white">
                            <tr>
                              <th>Group</th>
                              <th>Name</th>
                              <th>Address</th>
                              <th>Contact No</th>
                              <th>Gmail Id</th>
                            </tr>
                          </thead>

                          <tbody className="text-[#777777]">
                            {donorsData?.map((item, index) => (
                              <tr key={index}>
                                <td>{item.bloodGroup.name}</td>
                                <td>{item.name}</td>
                                <td>{item.address}</td>
                                <td>{item.phone}</td>
                                <td>{item.email}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div>
                        <p>No Data Found</p>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>

            {/*....Links....*/}
            <div className="w-full lg:w-[150px] flex-none flex flex-col items-center gap-[20px] my-[30px] ">
              <Link
                to="/volunteer-with-us"
                className="bg-[#D42F32] w-full px-[20px] py-[10px] text-white rounded-lg shadow-lg "
              >
                Volunteer with us
              </Link>

              <Link
                to="/register-as-donor"
                className="bg-[#D42F32] w-full px-[20px] py-[10px] text-white rounded-lg shadow-lg "
              >
                Register as Donor
              </Link>

              <Link
                to="/host-blood-drive"
                className="bg-[#D42F32] w-full px-[20px] py-[10px] text-white rounded-lg shadow-lg "
              >
                Host Blood Drive
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FindDonor;
