import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosBaseURL from "../utils/axios";
import { Loader, PageBanner, RecentBlogCard } from "../components";
import { author, lions_logo } from "../assets";
import { dateFormatter } from "../utils/dateFormatter";
import { Helmet } from "react-helmet";

const IndividualBlog = () => {
  let { slug } = useParams();

  const [loading, setLoading] = useState(false);
  const [blogDetails, setBlogDetails] = useState("");
  const [recentBlogs, setRecentBlogs] = useState([]);
  const [tagsArray, setTagsArray] = useState([]);
  // Fetching Blog Details
  const fetchBlogDetails = async (slug) => {
    setLoading(true);
    try {
      await axiosBaseURL.get(`/blog/${slug}`).then((res) => {
        if (res?.data?.data?.blog) {
          setBlogDetails(res?.data?.data?.blog);
          setLoading(false);
          return;
        }
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  // console.log(blogDetails);

  // Fetch Recent Blogs
  const fetchRecentBlogs = async () => {
    setLoading(true);
    try {
      await axiosBaseURL.get(`/recent-blogs`).then((res) => {
        if (res?.data?.data?.length > 0) {
          setRecentBlogs(res?.data?.data);
          // console.log(recentBlogs);
          return;
        }
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (slug) {
      fetchBlogDetails(slug);
      fetchRecentBlogs();
    }
  }, [slug]);

  useEffect(() => {
    if (blogDetails.tags) {
      const arrayOfTags = blogDetails.tags?.split(",");
      if (arrayOfTags?.length > 0) {
        setTagsArray(arrayOfTags);
      }
    }
  }, [blogDetails]);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{blogDetails?.title ? blogDetails.title : ""}</title>
        <meta
          name="description"
          content={
            blogDetails?.meta?.meta_description
              ? blogDetails?.meta?.meta_description
              : ""
          }
        />
      </Helmet>
      {/* <PageBanner image={blogDetails?.image && blogDetails?.image}/> */}
      <div className="section-padding">
        <div className="mx-auto max-w-[1600px] py-16">
          <div className="grid grid-cols-5 gap-8">
            <div className="xl:col-span-4 col-span-full">
              {loading ? (
                <div className="w-full flex justify-center items-center">
                  <Loader />
                </div>
              ) : blogDetails ? (
                <div className="mb-[30px] text-left">
                  <h1 className="font-bold text-3xl text-primary">
                    {blogDetails?.title}
                  </h1>
                  {/*....Author Details....*/}
                  <div className="w-max flex items-center mt-[20px] mb-6">
                    <img src={author} alt="authorImage" />
                    <div className="text-start">
                      <p className="text-[#505050] font-medium">
                        {blogDetails.author}
                      </p>
                      <p className="text-[#777777] text-[13px]">
                        {dateFormatter(blogDetails.updated_at)}
                      </p>
                    </div>
                  </div>

                  {/*....Main Image....*/}
                  {blogDetails?.image && (
                    <div className="blog-img mb-6 rounded-xl overflow-hidden">
                      <img
                        src={
                          blogDetails?.image ? blogDetails?.image : lions_logo
                        }
                        className="w-full lg:w-[100%] h-[400px] object-cover"
                        alt="mainImage"
                      />
                    </div>
                  )}

                  {/*....Details....*/}
                  {blogDetails.details && (
                    <div
                      className="details-content news-content leading-7"
                      dangerouslySetInnerHTML={{
                        __html: blogDetails.details,
                      }}
                    />
                  )}

                  {/*....Tags....*/}
                  <div className="w-[200px] px-[5px] flex items-center gap-[10px] flex-wrap mt-[30px]">
                    {tagsArray?.length > 0 &&
                      tagsArray.map((item, index) => (
                        <div
                          key={index}
                          className="w-max rounded-full px-[10px] py-[2px] border-2 border-slate-300 text-[#777777]"
                        >
                          {item}
                        </div>
                      ))}
                  </div>
                </div>
              ) : (
                <div>
                  <p>No Data Found</p>
                </div>
              )}
            </div>

            {/*...Recent Blogs....*/}
            <div className="block xl:col-span-1 col-span-full text-left">
              <h3 className="text-[#00539B] font-bold text-2xl mb-3">
                Recent Post
              </h3>
              <div className="grid md:grid-cols-2 grid-cols-1 gap-8 ">
                {recentBlogs?.length > 0 &&
                  recentBlogs?.slice(0, 2).map((item, index) => (
                    <div className="xl:col-span-full md:col-span-1 col-span-full">
                      <RecentBlogCard key={index} {...item} />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndividualBlog;
