import React from 'react'
import { Helmet } from 'react-helmet'
import { PageBanner } from '../components'
import { blood } from '../assets'
import parse from "html-react-parser";
import { useState } from 'react';
import axiosBaseURL from '../utils/axios';
import { useEffect } from 'react';

const ResearchPage = () => {
  const [bloodInfo, setBloodInfo] = useState();

  const fetchBloodInfo = async () => {
    try {
      await axiosBaseURL.get(`/blood-group-info-page`).then((res) => {
        if (res?.data?.data) {
          setBloodInfo(res?.data?.data);
          // console.log(bloodInfo);
          return;
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchBloodInfo();
  }, []);
  return (
    <div>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Research</title>
    </Helmet>
    <PageBanner title='Research' overlay="bg-[#00539B]" />

    <div className="resarch-page  section-padding">
      <div className="max-w-[1400px] mx-auto py-16 text-left">
        <div className="heading-wrapper">
          <h1 className="heading !text-3xl mb-4">Research</h1>
        </div>
        <div className="research-contents details-content leading-8">
          {bloodInfo?.academic_research && parse(bloodInfo?.academic_research)}
          </div>
      </div>
    </div>
  </div>
  )
}

export default ResearchPage