import React, { useState } from "react";
import { ContactCard, Loader, PageBanner } from "../components";
import axiosBaseURL from "../utils/axios";
import { Helmet } from "react-helmet";

const ContactUs = ({info}) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);

  console.log(info)
  // Handle Submit
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (name && email && subject && message) {
      setErrorMessage("");
      setSuccessMessage("");
      const formData = {
        name,
        type: "contact",
        email,
        subject,
        message,
      };

      try {
        setLoading(true);
        await axiosBaseURL
          .post("/submit-contact-form", formData)
          .then((res) => {
            setSuccessMessage(res.data.message);
            setLoading(false);
            setName("");
            setEmail("");
            setMessage("");
            setSubject("");
          });
      } catch (error) {
        console.log(error);
        setLoading(false);
        setSuccessMessage("");
        setErrorMessage(error.message);
      }
    } else {
      setSuccessMessage("");
      setErrorMessage("Fill all the fields");
    }
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact Us</title>
      </Helmet>
      <PageBanner title="Contact Us" />

      <div className="w-full flex flex-col items-center py-[20px]">
        <div className="py-[10px] px-[20px] w-full lg:w-[70%] border border-x-0 border-t-0 border-b-[#D1D1D1] flex items-center justify-between text-[#D42F32] font-bold">
          <p>Say Hello to Us</p>
          <p>Reach Us!</p>
        </div>

        <div className="w-full lg:w-[70%] flex flex-col lg:flex-row justify-between gap-[30px] py-[10px] px-[20px]">
          <form
            onSubmit={handleSubmit}
            className="w-full lg:w-[50%] flex flex-col gap-[20px]"
          >
            <label className="flex flex-col items-start gap-[5px]">
              <p className="font-bold">
                Name<span className="text-red-400">*</span>
              </p>
              <input
                type="text"
                value={name}
                placeholder="Lions Club"
                onChange={(e) => setName(e.target.value)}
                className="w-full border border-slate-300 py-[7px] px-[5px] rounded-[6px]"
              />
            </label>

            <label className="flex flex-col items-start gap-[5px]">
              <p className="font-bold">
                Email<span className="text-red-400">*</span>
              </p>
              <input
                type="email"
                value={email}
                placeholder="lionsclub@gmail.com"
                onChange={(e) => setEmail(e.target.value)}
                className="w-full border border-slate-300 py-[7px] px-[5px] rounded-[6px]"
              />
            </label>

            <label className="flex flex-col items-start gap-[5px]">
              <p className="font-bold">
                Subject<span className="text-red-400">*</span>
              </p>
              <input
                type="text"
                value={subject}
                placeholder="Lions Club"
                onChange={(e) => setSubject(e.target.value)}
                className="w-full border border-slate-300 py-[7px] px-[5px] rounded-[6px]"
              />
            </label>

            <label className="flex flex-col items-start gap-[5px]">
              <p className="font-bold">
                Message<span className="text-red-400">*</span>
              </p>
              <textarea
                placeholder="Your Message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className="w-full border border-slate-300 py-[7px] px-[5px] rounded-[6px]"
              />
            </label>

            {errorMessage && (
              <div>
                <p className="text-red-500">*{errorMessage}</p>
              </div>
            )}

            {loading && !successMessage ? (
              <Loader />
            ) : !loading && successMessage ? (
              <div>
                <p className="text-green-400 bg-white px-[10px] py-[6px] rounded-xl shadow-xl">
                  {successMessage}
                </p>
              </div>
            ) : (
              <button
                type="submit"
                className="w-max bg-[#D42F32] px-[20px] py-[7px] text-white rounded-lg"
              >
                Submit
              </button>
            )}
          </form>

          <ContactCard info={info} />
        </div>

        {/*....Map section..*/}
        <div className="w-full flex justify-center mt-[30px]">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d16807.229587696824!2d85.28693324464903!3d27.677591891843587!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb19661121686b%3A0xcef24f09ffb074bf!2sTU%20Lions%20Blood%20Transfusion%20%26%20Research%20Center%20(%23TULBTRC)!5e0!3m2!1sen!2snp!4v1687155014406!5m2!1sen!2snp"
            width="1140"
            height="579"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
