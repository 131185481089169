import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { changeNavState } from "../redux/navSlice";

const UpcomingEventCard = ({ image, title, description }) => {
  const dispatch = useDispatch();

  return (
    <div
      style={{
        border: "1px solid #C1C0C0",
      }}
      className=""
    >
      <div className="px-[20px] py-[40px] border-[#A6A6A6] border-b">
        <img src={image} alt="eventCardImage" />
      </div>
      <div className="w-full px-[20px] py-[30px] text-start flex flex-col items-start gap-[15px]">
        <p className="font-bold text-[#545050]">{title}</p>
        <div
          className="news-content"
          dangerouslySetInnerHTML={{
            __html:
              description?.length > 100
                ? description.slice(0, 100)
                : description,
          }}
        />
        <Link
          to="/events"
          onClick={() => {
            dispatch(changeNavState(null));
          }}
        >
          <button className="text-[#CD2142] font-bold font-roboto text-md ">
            See all Events
          </button>
        </Link>
      </div>
    </div>
  );
};

export default UpcomingEventCard;
