import React, { useEffect, useState } from "react";
// import { bannerImage } from "../assets";
import axiosBaseURL from "../utils/axios";

const PageBanner = ({ title, image, overlay, heightSpecified, hide }) => {
  const [bannerImg, setBannerImg] = useState("");

  const fetchDetails = async () => {
    try {
      await axiosBaseURL.get(`/breadcrumb`).then((res) => {
        if (res?.data) {
          setBannerImg(res?.data);
          return;
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchDetails();
  }, [setBannerImg]);
  return (
    <div
      className={`relative w-full ${
        heightSpecified ? heightSpecified : "h-[400px]"
      } ${hide ? "hidden lg:block" : "block"}`}
    >
      {bannerImg?.breadcrumb_img && (
        <img
          src={image ? image : bannerImg?.breadcrumb_img}
          className="w-full h-full object-cover"
          alt="BannerImage"
        />
      )}

      <div
        className={`${
          overlay ? overlay : "bg-black"
        } opacity-[20%] absolute top-0 left-0 right-0 bottom-0`}
      ></div>
      <p className="absolute top-[50%] left-[50%] transform -translate-x-[50%] -translate-y-[50%] text-white font-bold text-xl font-roboto z-1 ">
        {title ? title : ""}
      </p>
    </div>
  );
};

export default PageBanner;
