import React, { useEffect, useState } from "react";
import { Loader, PageBanner } from "../components";
import axiosBaseURL from "../utils/axios";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const RegisterAsDonor = () => {
  const [loading, setLoading] = useState(false);
  const [bloodGroups, setBloodGroups] = useState([]);
  const [age, setAge] = useState(0);
  const [sumbitloader, setSumbitloader] = useState();
  const [error, setError] = useState();

  const initialValues = {
    name: "",
    gender: "",
    birth_date: new Date(),
    phone: "",
    email: "",
    address: "",
    donate_before: "",
    blood_group: "",
    longevity: "",
    longevity_detail: null,
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("required"),
    gender: Yup.string().required("required"),
    birth_date: Yup.date()
      .required("Birthdate is required")
      .test("valid-age", "Age must be between 18 and 65", function (value) {
        const age = calculateAge(value);
        return age !== null && age >= 18 && age < 65;
      }),
    phone: Yup.string()
      .matches(/^[0-9]{10}$/, {
        message: "Phone No. must be exactly 10 digits",
        excludeEmptyString: true, // Ensure empty string is not allowed
      })
      .required("required"),
    // .min(10, "too short")
    // .max(10, "too long"),
    email: Yup.string()
      .email("Invalid email address")
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        {
          message: "Invalid email address",
        }
      )
      .required("required"),
    address: Yup.string().required("required"),
    donate_before: Yup.string().required("required"),
    // blood_group_id: Yup.number(),
    longevity: Yup.string().required("Required"),
  });

  // Fetch District
  // const fetchDistrict = async () => {
  //   setLoading(true);
  //   try {
  //     await axiosBaseURL.get(`/districts`).then((res) => {
  //       if (res?.data?.data?.length > 0) {
  //         setDistricts(res?.data?.data);
  //         setLoading(false);
  //         return;
  //       }
  //       setLoading(false);
  //     });
  //   } catch (error) {
  //     console.log(error);
  //     setLoading(false);
  //   }
  // };

  // Fetch Blood group info
  const fetchBloodGroup = async () => {
    setLoading(true);
    try {
      await axiosBaseURL.get(`/blood-groups`).then((res) => {
        if (res?.data?.data?.length > 0) {
          setBloodGroups(res?.data?.data);
          setLoading(false);
          return;
        }
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const calculateAge = (birth_date) => {
    if (!birth_date) return null;

    const birthDate = new Date(birth_date);
    const currentDate = new Date();

    let age = currentDate.getFullYear() - birthDate.getFullYear();
    const monthDiff = currentDate.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && currentDate.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };
  useEffect(() => {
    fetchBloodGroup();
  }, []);

  // Handle District Chnage
  // const handleDistrictChange = (event) => {
  //   setSelectedDistrict(event.target.value);
  // };

  // Handle Submit
  const handleSubmit = async (values, { resetForm }) => {
    if (65 > age && age < 18) {
      setError("");
      return;
    } else {
      setSumbitloader(true);
      try {
        await axiosBaseURL.post("/register/donors", values).then((res) => {
          if (res) {
            toast.success("Your Registation is submitted");
            setSumbitloader(false);
          }
          console.log(res);
          setError("");
          setAge(0);
          resetForm();
        });
      } catch (error) {
        setError(error?.response?.data?.message?.data);
        setSumbitloader(false);
        console.log(error?.response?.data?.message?.data);
      }
    }
  };
  return (
    <div className="relative">
      <ToastContainer />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Register as Donor</title>
        <meta name="description" content="" />
      </Helmet>
      <PageBanner title="Register as Donor" />

      <div className="w-full flex justify-center items-center section-padding">
        <div className="max-w-[1600px] w-full mx-auto flex flex-col items-center px-[20px] shadow-lg mb-[80px]">
          {/*...Register as donor text div....*/}
          <div className="w-full py-[40px] border-2 border-x-0 border-t-0 border-b-[#CC8282]">
            <p className="text-[#00539B] text-lg font-bold">
              Register as Donor
            </p>
          </div>
          {/*....Register as donor FORM DIV...*/}
          <div className="w-full lg:w-[70%] py-[30px] ">
            <p className="text-start">
              Confidential - Please answer the following questions correctly.
              This will help to protect you and the patient who receives your
              blood.
            </p>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {(formik) => (
                <Form onSubmit={formik.handleSubmit}>
                  <div className="grid md:grid-cols-3 grid-cols-1 gap-6 text-left">
                    <div className="col-span-full">
                      <div className="form-group">
                        <label
                          className={`form-label mb-1 block font-bold ${
                            formik.errors.name && formik.touched.name
                              ? "text-primary"
                              : ""
                          }`}
                        >
                          Full Name <span className="text-primary">*</span>
                        </label>
                        <Field
                          type="text"
                          name="name"
                          placeholder="Full name"
                          className={`w-full border outline-0 transition-[border] duration-300 autofill:bg-none focus:border-grey  border-slate-300 py-[7px] px-[5px] rounded-[6px] ${
                            formik.errors.name && formik.touched.name
                              ? "error"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="text-primary"
                        />
                      </div>
                    </div>
                    <div className="col-span-full">
                      <div className="form-group">
                        <label
                          className={`form-label mb-1 block  font-bold uppercase ${
                            formik.errors.gender && formik.touched.gender
                              ? "text-primary"
                              : ""
                          }`}
                        >
                          Choose your gender?
                          <span className="text-primary">*</span>
                        </label>
                        <div className="radio-group">
                          <label className="mr-3">
                            <Field
                              type="radio"
                              name="gender"
                              value="male"
                              className="mr-[2px]"
                            />
                            Male
                          </label>
                          <label>
                            <Field
                              type="radio"
                              name="gender"
                              value="female"
                              className="mr-[2px]"
                            />
                            Female
                          </label>
                        </div>

                        <ErrorMessage
                          name="gender"
                          component="div"
                          className="text-primary"
                        />
                      </div>
                    </div>

                    <div className="col-span-1">
                      <div className="form-group">
                        <label
                          className={`form-label mb-1 block  font-bold ${
                            formik.errors.birth_date &&
                            formik.touched.birth_date
                              ? "text-primary"
                              : ""
                          }`}
                        >
                          Date of Birth(AD)
                          <span className="text-primary">*</span>
                        </label>
                        <DatePicker
                          showIcon
                          selected={formik.values.birth_date}
                          onChange={(date) => {
                            formik.setFieldValue("birth_date", date);
                            const age = calculateAge(date);
                            setAge(age);
                          }}
                          dateFormat="dd/MM/yyyy"
                          maxDate={new Date()}
                          className="w-full border border-slate-300 py-2 px-3 rounded-md"
                        />
                        <ErrorMessage
                          name="birth_date"
                          component="div"
                          className="text-primary"
                        />
                      </div>
                    </div>
                    <div className="col-span-1">
                      <div className="form-group">
                        <label className={`form-label mb-1 block  font-bold`}>
                          Age
                        </label>
                        <p
                          className={`w-full border bg-gray-100 border-slate-300 py-[7px] px-[5px] rounded-[6px] outline-0 transition-[border] duration-300 autofill:bg-none focus:border-grey`}
                        >
                          {age}
                        </p>
                      </div>
                    </div>
                    <div className="col-span-1">
                      <div className="form-group">
                        <label
                          className={`form-label mb-1 block  font-bold ${
                            formik.errors.phone && formik.touched.phone
                              ? "text-primary"
                              : ""
                          }`}
                        >
                          Contact Number <span className="text-primary">*</span>
                        </label>
                        <Field
                          type="number"
                          name="phone"
                          placeholder="Mobile No."
                          className={`w-full border border-slate-300 py-[7px] px-[5px] rounded-[6px] outline-0 transition-[border] duration-300 autofill:bg-none focus:border-grey ${
                            formik.errors.phone && formik.touched.phone
                              ? "error"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          name="phone"
                          component="div"
                          className="text-primary"
                        />
                      </div>
                    </div>
                    <div className="col-span-1">
                      <div className="form-group">
                        <label
                          className={`form-label mb-1 block  font-bold ${
                            formik.errors.email && formik.touched.email
                              ? "text-primary"
                              : ""
                          }`}
                        >
                          Email address <span className="text-primary">*</span>
                        </label>
                        <Field
                          type="email"
                          name="email"
                          placeholder="Email"
                          className={`w-full border border-slate-300 py-[7px] px-[5px] rounded-[6px] outline-0 transition-[border] duration-300 autofill:bg-none focus:border-grey ${
                            formik.errors.email && formik.touched.email
                              ? "error"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-primary"
                        />
                      </div>
                    </div>
                    <div className="col-span-1">
                      <div className="form-group">
                        <label
                          className={`form-label mb-1 block  font-bold ${
                            formik.errors.address && formik.touched.address
                              ? "text-primary"
                              : ""
                          }`}
                        >
                          Address <span className="text-primary">*</span>
                        </label>
                        <Field
                          type="text"
                          name="address"
                          placeholder="Address"
                          className={`w-full border border-slate-300 py-[7px] px-[5px] rounded-[6px] outline-0 transition-[border] duration-300 autofill:bg-none focus:border-grey ${
                            formik.errors.address && formik.touched.address
                              ? "error"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          name="address"
                          component="div"
                          className="text-primary"
                        />
                      </div>
                    </div>
                    <div className="col-span-full">
                      <div className="form-group">
                        <label
                          className={`form-label mb-1 block  font-bold ${
                            formik.errors.donate_before &&
                            formik.touched.donate_before
                              ? "text-primary"
                              : ""
                          }`}
                        >
                          Have you ever donated blood before?{" "}
                          <span className="text-primary">*</span>
                        </label>
                        <div className="radio-group">
                          <label className="mr-3">
                            <Field
                              type="radio"
                              name="donate_before"
                              value="yes"
                              className="mr-[2px]"
                            />
                            Yes
                          </label>
                          <label>
                            <Field
                              type="radio"
                              name="donate_before"
                              value="no"
                              className="mr-[2px]"
                            />
                            No
                          </label>
                        </div>

                        <ErrorMessage
                          name="donate_before"
                          component="div"
                          className="text-primary"
                        />
                      </div>
                    </div>
                    <div className="col-span-full">
                      <div className="form-group">
                        <label
                          className={`form-label mb-1 block  font-bold ${
                            formik.errors.blood_group &&
                            formik.touched.blood_group
                              ? "text-primary"
                              : ""
                          }`}
                        >
                          Blood Group
                        </label>
                        <div className="radio-group max-w-[250px] flex flex-wrap gap-3">
                          {bloodGroups?.length > 0 && (
                            <>
                              {bloodGroups.map((item) => (
                                <label key={item.id} className="w-[40%]">
                                  <Field
                                    type="radio"
                                    name="blood_group"
                                    id={item.id}
                                    value={item.id}
                                    checked={
                                      formik.values.blood_group === item.id
                                    }
                                    onChange={() =>
                                      formik.setFieldValue(
                                        "blood_group",
                                        item.id
                                      )
                                    }
                                    className="mr-[2px]"
                                  />
                                  {item?.name}
                                </label>
                              ))}
                            </>
                          )}
                        </div>

                        <ErrorMessage
                          name="blood_group"
                          component="div"
                          className="text-primary"
                        />
                      </div>
                    </div>
                    <div className="col-span-full">
                      <div className="form-group">
                        <label
                          className={`form-label mb-1 block  font-bold ${
                            formik.errors.longevity && formik.touched.longevity
                              ? "text-primary"
                              : ""
                          }`}
                        >
                          Have you have any Longevity?
                          <span className="text-primary"> *</span>
                        </label>
                        <div className="radio-group">
                          <label className="mr-3">
                            <Field
                              type="radio"
                              name="longevity"
                              value="yes"
                              className="mr-[2px]"
                            />
                            Yes
                          </label>
                          <label>
                            <Field
                              type="radio"
                              name="longevity"
                              value="no"
                              className="mr-[2px]"
                            />
                            No
                          </label>
                        </div>

                        <ErrorMessage
                          name="longevity"
                          component="div"
                          className="text-primary"
                        />
                      </div>
                    </div>
                    {formik.values.longevity === "yes" && (
                      <>
                        <div className="col-span-full">
                          <div className="form-group">
                            <label
                              className={`form-label mb-1 block ${
                                formik.errors.longevity_detail &&
                                formik.touched.longevity_detail
                                  ? "text-primary"
                                  : ""
                              }`}
                            >
                              If yes please Descirbe
                            </label>
                            <Field
                              type="text"
                              name="longevity_detail"
                              placeholder="Please Describe"
                              className={`w-full border border-slate-300 py-[7px] px-[5px] rounded-[6px] outline-0 transition-[border] duration-300 autofill:bg-none focus:border-grey ${
                                formik.errors.longevity_detail &&
                                formik.touched.longevity_detail
                                  ? "error"
                                  : ""
                              }`}
                            />
                          </div>
                        </div>
                      </>
                    )}
                    <div className="col-span-full">
                      {error && (
                        <>
                          {Object.keys(error).map((key) => (
                            <div key={key}>
                              {error[key].map((errorMessage, index) => (
                                <p className="text-primary" key={index}>
                                  {errorMessage}
                                </p>
                              ))}
                            </div>
                          ))}
                        </>
                      )}
                      <div className="btn-wrapper">
                        <button type="submit" className="btn-primary">
                          {sumbitloader ? (
                            <div class="submitloader"></div>
                          ) : (
                            "Submit"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>

      <div className="bg-[#D42F32] text-white fixed bottom-[50px] right-[20px] lg:right-[50px] rounded-full w-[100px] h-[100px]">
        <Link
          to="/schedule-blood-donation"
          className="w-full h-full pt-[10px]  inline-block"
        >
          Schedule Blood Donation
        </Link>
      </div>
    </div>
  );
};

export default RegisterAsDonor;
