import React, { useEffect, useState } from "react";
import { Dropdown, Loader, PageBanner } from "../components";
import { Helmet } from "react-helmet";
import axiosBaseURL from "../utils/axios";
import { whereDidYouHear } from "../data/whereDidYouHear";

const HostBloodDrive = () => {
  const [selectedOrganizationType, setSelectedOrganizationType] = useState();
  const [selectedFromWhereDidYouHear, setSelectedFromWhereDidYouHear] =
    useState("");
  const [fullName, setFullName] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState();
  const [title, setTitle] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [loacationDrive, setLoacationDrive] = useState("");
  const [haveEverHostedBloodDrive, setHaveEverHostedBloodDrive] = useState();
  const [
    fromWhereDidYouHearAboutBloodBank,
    setFromWhereDidYouHearAboutBloodBank,
  ] = useState("");
  const [loading, setLoading] = useState(false);
  const [organizationType, setOrganizationType] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // Fetch Organization Type
  const fetchOrganizationType = async () => {
    try {
      await axiosBaseURL.get(`/organization-type-lists`).then((res) => {
        if (res.data?.data?.length > 0) {
          setOrganizationType(res.data.data);
          setLoading(false);
          return;
        }
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrganizationType();
  }, []);

  // Handle Organization Change
  const handleOrganizationChange = (e) => {
    setSelectedOrganizationType(e.target.value);
  };

  // Handle How did you hear about TU lions Blood Bank
  const handleHowDidYouHear = (e) => {
    setSelectedFromWhereDidYouHear(e.target.value);
  };

  // Handle Submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      fullName &&
      email &&
      phone &&
      organizationName &&
      selectedOrganizationType &&
      title &&
      city &&
      province &&
      loacationDrive &&
      haveEverHostedBloodDrive &&
      selectedFromWhereDidYouHear
    ) {
      if (phone?.length !== 10) {
        setErrorMessage("Phone Number must be 10 digit.");
        return;
      }
      const formData = {
        contact_name: fullName,
        organizer_email: email,
        organizer_phone: phone,
        organizer: organizationName,
        organizer_type: selectedOrganizationType,
        title: title,
        city: city,
        province: province,
        location: loacationDrive,
        host_before: haveEverHostedBloodDrive,
        known_from: selectedFromWhereDidYouHear,
      };
      try {
        setLoading(true);
        await axiosBaseURL
          .post(`/submit-host-blood-form`, formData)
          .then((res) => {
            if (res.data) {
              setLoading(false);
              setSuccessMessage(res.data.message);
              return;
            }
            setLoading(false);
          });
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    } else {
      setErrorMessage("Fill All the Fields");
    }
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Host Blood Drive</title>
        <meta name="description" content="" />
      </Helmet>
      <PageBanner title="Host a Blood Drive" />

      <div className="px-[20px] lg:px-[60px] flex flex-col items-center gap-[20px] mt-[30px]">
        <p className="text-[#00539B] text-2xl font-bold text-center ">
          Host a Blood Drive
        </p>

        <p className="w-full lg:w-[60%] text-start">
          We appreciate your interest in the TU Lions Blood Program. A TU Lions
          representative will get in touch with you as soon as your information
          is submitted. We appreciate your dedication to helping sick patients
          and maintaining the well-being of your community.
        </p>

        <div className="w-full flex flex-col items-center">
          {/*...Contact Information form....*/}
          <div className="w-full lg:w-[500px] flex flex-col gap-[15px]">
            <p className="font-bold text-lg">Contact Information</p>

            <form
              onSubmit={handleSubmit}
              className="flex flex-col gap-[15px] pb-[50px]"
            >
              <label className="flex flex-col items-start gap-[5px]">
                <p className="font-bold">Full Name</p>
                <input
                  type="text"
                  placeholder="Enter your name"
                  onChange={(e) => setFullName(e.target.value)}
                  className="w-full border border-slate-300 py-[7px] px-[5px] rounded-[6px]"
                />
              </label>

              <label className="flex flex-col items-start gap-[5px]">
                <p className="font-bold">Organization Name</p>
                <input
                  type="text"
                  placeholder="Enter your orginaizations"
                  onChange={(e) => setOrganizationName(e.target.value)}
                  className="w-full border border-slate-300 py-[7px] px-[5px] rounded-[6px]"
                />
              </label>

              <label className="flex flex-col items-start gap-[5px]">
                <p className="font-bold">Organization Type</p>

                <Dropdown
                  dropdownTitle="Organization Type"
                  data={organizationType}
                  selectedData={selectedOrganizationType}
                  onChange={handleOrganizationChange}
                />
              </label>

              <label className="flex flex-col items-start gap-[5px]">
                <p className="font-bold">Email</p>
                <input
                  type="email"
                  placeholder="Enter your email"
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full border border-slate-300 py-[7px] px-[5px] rounded-[6px]"
                />
              </label>

              <label className="flex flex-col items-start gap-[5px]">
                <p className="font-bold">Phone</p>
                <input
                  type="number"
                  placeholder="Enter your phone number"
                  onChange={(e) => setPhone(e.target.value)}
                  className="w-full border border-slate-300 py-[7px] px-[5px] rounded-[6px]"
                />
              </label>

              <label className="flex flex-col items-start gap-[5px]">
                <p className="font-bold">Title</p>
                <input
                  type="text"
                  placeholder="Enter The Title"
                  onChange={(e) => setTitle(e.target.value)}
                  className="w-full border border-slate-300 py-[7px] px-[5px] rounded-[6px]"
                />
              </label>

              <div className="w-full flex items-center justify-between gap-[30px]">
                <label className="w-[200px] flex flex-col items-start gap-[5px]">
                  <p className="font-bold">City</p>
                  <input
                    type="text"
                    placeholder="Enter Your City"
                    onChange={(e) => setCity(e.target.value)}
                    className="w-full border border-slate-300 py-[7px] px-[5px] rounded-[6px]"
                  />
                </label>

                <label className="w-[200px] flex flex-col items-start gap-[5px]">
                  <p className="font-bold">Province</p>
                  <input
                    type="text"
                    placeholder="Enter Your Province"
                    onChange={(e) => setProvince(e.target.value)}
                    className="w-full border border-slate-300 py-[7px] px-[5px] rounded-[6px]"
                  />
                </label>
              </div>

              <label className="flex flex-col items-start gap-[5px]">
                <p className="font-bold">Location Drive</p>
                <input
                  type="text"
                  placeholder="Enter location"
                  onChange={(e) => setLoacationDrive(e.target.value)}
                  className="w-full border border-slate-300 py-[7px] px-[5px] rounded-[6px]"
                />
              </label>

              <div className="flex flex-col items-start gap-[10px]">
                <p className="font-bold">
                  Have your organization ever hosted blood drive before?{" "}
                </p>
                <div className="flex items-center gap-[50px]">
                  <div className="flex gap-[5px]">
                    <input
                      type="radio"
                      id="yes"
                      name="yes"
                      value="yes"
                      checked={
                        haveEverHostedBloodDrive === "yes" ? true : false
                      }
                      onChange={(e) => setHaveEverHostedBloodDrive(e.target.id)}
                    />
                    <label htmlFor="yes">Yes</label>
                  </div>
                  <div className="flex gap-[5px]">
                    <input
                      type="radio"
                      id="no"
                      name="no"
                      value="no"
                      checked={haveEverHostedBloodDrive === "no" ? true : false}
                      onChange={(e) => setHaveEverHostedBloodDrive(e.target.id)}
                    />
                    <label htmlFor="no">No</label>
                  </div>
                </div>
              </div>

              <label className="flex flex-col items-start gap-[5px]">
                <p className="font-bold text-start">
                  How did you hear about hosting a blood drive with TU lions
                  Blood bank?{" "}
                </p>

                <Dropdown
                  dropdownTitle="Where did you hear"
                  data={whereDidYouHear}
                  selectedData={selectedFromWhereDidYouHear}
                  onChange={handleHowDidYouHear}
                />
              </label>

              {errorMessage && (
                <div>
                  <p className="text-red-500">*{errorMessage}</p>
                </div>
              )}

              {loading ? (
                <Loader />
              ) : successMessage ? (
                <div>
                  <p className="text-green-400 bg-white text-lg font-bold px-[10px] py-[6px] rounded-xl shadow-xl">
                    {successMessage}
                  </p>
                </div>
              ) : (
                <button
                  type="submit"
                  className="w-max bg-[#D42F32] px-[20px] py-[7px] text-white rounded-lg mt-[10px]"
                >
                  Submit
                </button>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HostBloodDrive;
