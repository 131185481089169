import React, { useEffect, useState } from "react";
import axiosBaseURL from "../utils/axios";
import { useParams } from "react-router-dom";
import { Loader, PageBanner } from "../components";
import { galleryBanner } from "../assets";

const IndividualGallery = () => {
  let { slug } = useParams();

  const [loading, setLoading] = useState(false);
  const [galleryData, setGalleryData] = useState([]);
  const [images, setImages] = useState([]);
  const [mainImage, setMainImage] = useState();

  // Fetching Gallery details
  const fetchGalleryImages = async (slug) => {
    setLoading(true);
    try {
      await axiosBaseURL.get(`/gallery-categories/${slug}`).then((res) => {
        if (res?.data?.data?.length > 0) {
          setGalleryData(res?.data?.data);
          setLoading(false);
          return;
        }
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (slug) {
      fetchGalleryImages(slug);
    }
  }, [slug]);

  // Categorising data to display differently
  useEffect(() => {
    if (galleryData?.length > 0) {
      setMainImage(galleryData[0]);

      const belowData = galleryData?.filter(
        (item) => galleryData.indexOf(item) !== 0
      );
      setImages(belowData);
    }
  }, [galleryData]);

  // Change image display
  const changeImageDisplay = (imageData) => {
    if (imageData) {
      setMainImage(imageData);
    }
  };

  return (
    <div className="py-[30px] px-[20px]">
      <PageBanner image={galleryBanner} overlay="bg-[#00539B]" hide={true}/>

      <div className="w-full flex flex-col items-center mt-[30px]">
        {loading ? (
          <div className="w-full flex justify-center items-center">
            <Loader />
          </div>
        ) : galleryData?.length > 0 ? (
          <div className="w-full flex flex-col items-center gap-[30px]">
            {mainImage && (
              <div className="w-full lg:w-[70%] flex flex-col gap-[10px] ">
                <img
                  src={mainImage.image}
                  className="w-full h-[300px] lg:h-[400px] object-contain rounded-lg"
                  alt="topImage"
                />
                <p className="text-[#D42F32] font-medium">{mainImage.title}</p>
              </div>
            )}

            <div className="w-full overflow-x-scroll scrollbar-hide flex justify-start items-center gap-[30px]">
              {images?.length > 0 &&
                images.map((item, index) => (
                  <div
                    key={index}
                    onClick={() => changeImageDisplay(item)}
                    className="flex flex-col text-start gap-[10px]"
                  >
                    <img
                      src={item.image}
                      className="w-[200px] h-[250px] object-cover rounded-lg"
                      alt="individualImage"
                    />
                    <p className="font-medium">{item.title}</p>
                  </div>
                ))}
            </div>
          </div>
        ) : (
          <div>
            <p>No Data Found</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default IndividualGallery;
