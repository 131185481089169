import React, { useRef } from "react";
import { bannerImage } from "../assets";
import { Link } from "react-router-dom";
import { Navigation, Autoplay, EffectCreative } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
// import Swiper and modules styles
import "swiper/css";
import "swiper/css/navigation";
import { changeNavState } from "../redux/navSlice";
import { useDispatch } from "react-redux";

const HeroBanner = ({ bannerData }) => {
  const dispatch = useDispatch();
  // console.log(bannerData);
  const progressCircle = useRef(null);
  const progressContent = useRef(null);
  const onAutoplayTimeLeft = (s, time, progress) => {
    progressCircle.current.style.setProperty("--progress", 1 - progress);
    progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
  };
  return (
    <div className="hero-section relative">
      <Swiper
        slidesPerView={1}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        navigation={true}
        effect={"creative"}
        creativeEffect={{
          prev: {
            shadow: true,
            translate: ["-20%", 0, -1],
          },
          next: {
            translate: ["100%", 0, 0],
          },
        }}
        modules={[Autoplay, Navigation, EffectCreative]}
        onAutoplayTimeLeft={onAutoplayTimeLeft}
        className="mySwiper"
      >
        {bannerData?.map((item, index) => (
          <SwiperSlide key={index}>
            <div className="relative bg-[#fbfbfb] banner-wrapper">
              <div className=" section-padding">
                <div className="max-w-[1400px] mx-auto text-left">
                  <div className="grid grid-cols-3">
                    <div className="w-full lg:col-span-2 col-span-full order-1 lg:-order-none lg:py-0 py-10">
                      <div className=" flex flex-col justify-center h-full max-w-[600px]">
                        <article>
                          <h3 className="text-[30px] lg:text-[46px] leading-tight capitalize heading-big text-primary mb-4 line-clamp-2 text-balance">
                            {item?.title}
                          </h3>
                          <p className="text-[18px] font-[400] mb-8 line-clamp-3">
                            {item?.description}
                          </p>
                        </article>
                        <Link to={`/register-as-donor`}>
                          <button
                            className="btn-primary !py-3 !px-16"
                            onClick={() => {
                              dispatch(changeNavState(5));
                            }}
                          >
                            Donate now
                          </button>
                        </Link>
                      </div>
                    </div>
                    <figure className="lg:col-span-1 col-span-full lg:mx-0 -mx-[20px] md:h-[500px] h-[400px] ">
                      <img
                        src={item?.image ? item?.image : bannerImage}
                        className="w-full h-full object-cover"
                        alt={item?.title}
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
        <div className="autoplay-progress" slot="container-end">
          <svg viewBox="0 0 48 48" ref={progressCircle}>
            <circle cx="24" cy="24" r="20"></circle>
          </svg>
          <span ref={progressContent}></span>
        </div>
      </Swiper>
    </div>
  );
};

export default HeroBanner;
