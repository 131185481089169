import React, { useEffect, useState } from "react";
import axiosBaseURL from "../utils/axios";
import { Helmet } from "react-helmet";
import { Loader } from "../components";

const Video = () => {
  const [loading, setLoading] = useState(false);
  const [audioVideo, setAudioVideo] = useState("");

  const fetchVideoAudio = async () => {
    setLoading(true);
    try {
      await axiosBaseURL.get(`/video-audio`).then((res) => {
        if (res?.data) {
          setAudioVideo(res?.data);
          setLoading(false);
          return;
        }
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchVideoAudio();
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Video Listing</title>
        <meta name="description" content="" />
      </Helmet>
      <div className="section-padding">
        <div className="video-section max-w-[1200px] mx-auto py-10 px-5 ">
          {loading ? (
            <div className="w-full flex justify-center items-center">
              <Loader />
            </div>
          ) : (
            Object.keys(audioVideo)?.length > 0 && (
              <>
                {audioVideo?.video?.length > 0 && (
                  <div className="video-wrapper">
                    <h4 className="text-[#00539B] text-xl text-left font-semibold mb-5">
                      Video Section
                    </h4>
                    <div className="video-group">
                      <div className=" grid lg:grid-cols-3 md:grid-cols-2 grid-col-1 gap-8">
                        {audioVideo?.video?.map((item, index) => (
                          <div className={`col-span-1 `} key={index}>
                            <div className="iframe-wrapper xl:h-[300px] h-[240px] w-full rounded-2xl overflow-hidden">
                              <iframe
                                src={item.url}
                                title="YouTube video player"
                                className="h-full w-full"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin"
                                allowFullScreen
                              ></iframe>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </>
            )
          )}
        </div>
      </div>
    </>
  );
};

export default Video;
