import React, { useEffect, useState } from "react";
import { Dropdown, Loader } from "../components";
import { organizationType } from "../data/organizationType";
import axiosBaseURL from "../utils/axios";
import { Helmet } from "react-helmet";

const ScheduleBlooodDonation = () => {
  const [selectedDonationCentre, setselectedDonationCentre] = useState("");
  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState("");
  const [date, setDate] = useState();
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [donation_center, setDonation_center] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  // Handle donation centre
  const handleDonationCentre = (e) => {
    setselectedDonationCentre(e.target.value);
  };

  // Fetch Blood Donation Center
  const fetchBloodDonationCenter = async () => {
    try {
      await axiosBaseURL.get(`/donor-center-lists`).then((res) => {
        if (res.data?.data?.length > 0) {
          setDonation_center(res.data.data);
          setLoading(false);
          return;
        }
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchBloodDonationCenter();
  }, []);

  // Handle Submit
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      fullName &&
      phone &&
      email &&
      date &&
      message &&
      selectedDonationCentre
    ) {
      if (phone.length !== 10) {
        setErrorMessage("Phone Number must be 10 digit");
        return;
      }
      
      const formData = {
        name: fullName,
        phone: phone,
        email: email,
        schedule_date: date,
        message: message,
        donation_center: selectedDonationCentre,
      };

      setErrorMessage("");
      setLoading(true);
      try {
        await axiosBaseURL
          .post(`/submit-donation-form`, formData)
          .then((res) => {
            if (res) {
              setSuccessMessage(res.data.message);
              console.log(res);
              setLoading(false);
              return;
            }
            setLoading(false);
          });
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    } else {
      setErrorMessage("Please fill all the fields.");
    }
  };

  return (
    <div className="w-full flex flex-col items-center gap-[40px] py-[40px] px-[20px] lg:px-[40px]">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Schedule Blood Donation</title>
        <meta name="description" content="" />
      </Helmet>
      <p className="text-[#00539B] text-2xl font-bold text-center ">
        "Give the Gift of Life: Schedule Your Blood Donation Now"
      </p>

      <form
        onSubmit={handleSubmit}
        className="w-full lg:w-[900px] flex flex-col gap-[15px] border-2 p-[10px] lg:p-[40px]"
      >
        <label className="flex flex-col items-start gap-[5px]">
          <p className="font-bold">Full Name</p>
          <input
            type="text"
            placeholder="Enter your name"
            onChange={(e) => setFullName(e.target.value)}
            className={`w-full border border-slate-300 py-[7px] px-[5px] rounded-[6px] `}
          />
        </label>

        <div className="flex items-center justify-between flex-wrap gap-[15px] ">
          <label className="flex flex-col items-start gap-[5px] w-full">
            <p className="font-bold">Enter Phone Number</p>
            <input
              type="text"
              placeholder="+977-12238126"
              onChange={(e) => setPhone(e.target.value)}
              className={`w-full lg:w-[300px] border border-slate-300 py-[7px] px-[5px] rounded-[6px]`}
            />
          </label>

          <label className="flex flex-col items-start gap-[5px] w-full">
            <p className="font-bold">Enter Email</p>
            <input
              type="text"
              placeholder="abc@gmail.com"
              onChange={(e) => setEmail(e.target.value)}
              className={`w-full lg:w-[300px] border border-slate-300 py-[7px] px-[5px] rounded-[6px]`}
            />
          </label>

          <label className="flex flex-col items-start gap-[5px] w-full">
            <p className="font-bold">Select Date</p>
            <input
              type="date"
              onChange={(e) => setDate(e.target.value)}
              className={`w-full lg:w-[300px] border border-slate-300 py-[7px] px-[5px] rounded-[6px] `}
            />
          </label>

          <label className="flex flex-col items-start gap-[5px] w-full">
            <p className="font-bold">Choose Donation center</p>

            <Dropdown
              dropdownTitle="Business"
              data={donation_center}
              selectedData={selectedDonationCentre}
              onChange={handleDonationCentre}
              isWidthSpecified={false}
              style={`md:w-[300px] w-full`}
            />
          </label>
        </div>

        <label className="flex flex-col items-start gap-[5px]">
          <p className="font-bold">Your Message</p>
          <textarea
            onChange={(e) => setMessage(e.target.value)}
            className={`w-full h-[100px] border border-slate-300 py-[7px] px-[5px] rounded-[6px]  `}
          />
        </label>
        {errorMessage && (
          <div className="py-[10px]">
            <p className="text-red-500 text-start">*{errorMessage}</p>
          </div>
        )}
        {loading ? (
          <Loader />
        ) : successMessage ? (
          <div>
            <p className="text-green-400 bg-white px-[10px] py-[6px] rounded-xl shadow-xl">
              {successMessage}
            </p>
          </div>
        ) : (
          <button
            type="submit"
            className="w-max bg-[#D42F32] px-[20px] py-[7px] text-white rounded-lg mt-[10px]"
          >
            Submit
          </button>
        )}
      </form>
    </div>
  );
};

export default ScheduleBlooodDonation;
