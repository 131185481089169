import React from "react";
import { lions_logo} from "../assets";

const BoardMemberCard = ({ image, name, position,className }) => {
  return (
    <div className={`flex flex-col items-center gap-[5px] text-center ${className && className}`}>
      <img
        src={image ? image : lions_logo}
        className="w-[182px] h-[182px] rounded-full object-contain"
        alt="boardMemberImage"
      />
      <div className="w-full flex flex-col items-center bg-red-400 rounded-lg px-[5px] py-[10px]">
        <p className="w-full px-[10px] text-white lg:text-lg text-[15px] font-medium">
          {name}
        </p>
        <p className="w-full text-sm font-medium">{position}</p>
      </div>
    </div>
  );
};

export default BoardMemberCard;
