import React from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { useDispatch } from "react-redux";
import { changeNavState } from "../redux/navSlice";

const BloodLionsIntro = ({ image, description }) => {
  const dispatch = useDispatch();

  const truncateAndParse = (content) => {
    if (!content) return "";
    const truncatedContent =
      content.length > 400 ? content.slice(0, 400) + "..." : content;
    return parse(truncatedContent);
  };
  return (
    <div className="w-full flex flex-col items-start gap-[20px] text-justify">
      <img
        src={image}
        className="w-full h-[350px] object-cover object-center rounded-2xl"
        alt="joint-meeting"
      />

      {description && (
        <>
          {truncateAndParse(description)}
        </>
      )}

      <Link to="/about-us"  onClick={() => {
              dispatch(changeNavState(1));
            }}>
        <button className="text-[#CD2142] font-bold font-roboto text-md ">
          Learn more
        </button>
      </Link>
    </div>
  );
};

export default BloodLionsIntro;
