import React, { useEffect } from "react";
import "photoswipe/style.css";
import "photoswipe-dynamic-caption-plugin/photoswipe-dynamic-caption-plugin.css";

const GalleryCard = ({ GalleryData }) => {
  useEffect(() => {
    const initializePhotoSwipe = async () => {
      const [PhotoSwipeModule, PhotoSwipeDynamicCaptionModule] =
        await Promise.all([
          import("photoswipe"),
          import("photoswipe-dynamic-caption-plugin"),
        ]);

      const { default: PhotoSwipeLightbox } = await import(
        "photoswipe/lightbox"
      );

      // Create a new instance of PhotoSwipeLightbox
      const lightbox = new PhotoSwipeLightbox({
        gallerySelector: "#gallery",
        childSelector: "a",
        pswpModule: () => PhotoSwipeModule,
      });

      lightbox.init();

      // Add the PhotoSwipeDynamicCaption plugin to the lightbox
      const captionPlugin = new PhotoSwipeDynamicCaptionModule.default(
        lightbox,
        {
          mobileLayoutBreakpoint: 700,
          type: "auto",
          mobileCaptionOverlapRatio: 1,
        }
      );

      return () => {
        lightbox.destroy();
      };
    };

    initializePhotoSwipe();
  }, []);

  // Function to handle image load and set data attributes
  const handleImageLoad = (index, event) => {
    const { naturalWidth, naturalHeight } = event.target;
    const aElement = event.target.parentNode;

    aElement.setAttribute("data-pswp-width", naturalWidth);
    aElement.setAttribute("data-pswp-height", naturalHeight);
  };

  return (
    <div className="pswp-gallery" id="gallery">
      <div className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-4">
        {GalleryData.map((item, index) => (
          <React.Fragment key={index}>
            {item?.gallery?.map((galleryitem, index) => (
              <div className="col-span-1" key={index}>
                <a
                  href={galleryitem.image}
                  data-index={index}
                  target="_blank"
                  rel="noreferrer"
                  className="w-full xl:h-[270px] md:h-[240px] h-[160px] block overflow-hidden rounded-[20px]"
                >
                  <img
                    src={galleryitem.image}
                    alt={galleryitem.title}
                    onLoad={(event) => handleImageLoad(index, event)}
                    className="w-full h-full object-cover object-center"
                  />
                  <div className="pswp-caption-content">
                    <h3>{galleryitem.title}</h3>
                    {/* <h3>{galleryitem.event}</h3> */}
                  </div>
                </a>
              </div>
            ))}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default GalleryCard;
