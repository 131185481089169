import React from "react";

const Dropdown = ({
  dropdownTitle,
  data,
  selectedData,
  onChange,
  isWidthSpecified,
  style,
}) => {
  return (
    <select
      value={selectedData}
      onChange={onChange}
      className={`${
        style ? style : isWidthSpecified ? "w-[200px]" : "w-full"
      } py-[7px] border-2 border-[#777777] rounded-[4px] px-[5px] ${style}`}
    >
      <option value="">{dropdownTitle}</option>
      {data.map((item, index) => (
        <option key={index} value={item.id ? item.id : item.slug}>
          {item.name}
        </option>
      ))}
    </select>
  );
};

export default Dropdown;
