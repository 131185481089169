import React, { useEffect, useState } from "react";
import {
  BloodInventoryCircle,
  BloodLionsIntro,
  DiscoverHowToDonate,
  HeroBanner,
  Popup,
  QuantityBtn,
  RealStory,
  UpcomingEventCard,
  UserActivityCard,
} from "../components";
import { icon1, icon2, icon3, logo } from "../assets";
import axiosBaseURL from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { changeNoticePopup } from "../redux/navSlice";
import parse from "html-react-parser";

const Root = ({ info }) => {
  const dispatch = useDispatch();

  const [homePageDetails, setHomePageDetails] = useState();
  const [bloodData, setBloodData] = useState();
  const [popupActive, setPopupActive] = useState(false);

  const activityData = [
    {
      title: "Become a Donor",
      info: "Join us in saving lives by donating blood. Click here to register as a donor and make a difference today",
      image: icon3,
      link: "/register-as-donor",
    },
    {
      title: "Get Involved",
      info: "We welcome you to be part of our community. Click here to explore volunteer opportunities and support our cause",
      image: icon2,
      link: "/volunteer-with-us",
    },
    {
      title: "Host Blood Drive",
      info: "Make a lasting impact by hosting a blood drive. Click here to learn how to sponsor an event and contribute to our mission of saving lives through blood donation.",
      image: icon1,
      link: "/host-blood-drive",
    },
  ];

  const activePopup = useSelector(
    (state) => state.navState.isNoticePopupActuve
  );
  // Fetch Home Page Data
  const fetchHomePageData = async () => {
    try {
      await axiosBaseURL.get(`/home-page-info`).then((res) => {
        if (res?.data?.data) {
          
          setHomePageDetails(res.data.data);
          return;
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  const fetchBloodDage = async () => {
    try {
      await axiosBaseURL.get(`/blood-page`).then((res) => {
        if (res?.data?.data) {
          setBloodData(res.data.data);
          return;
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchHomePageData();
    fetchBloodDage();
  }, []);

  useEffect(() => {
    if (homePageDetails?.pop_up?.length > 0) {
      setTimeout(() => {
        setPopupActive(true);
      }, 2000);
    }
  }, [homePageDetails]);

  // Handle Cross
  const handleCross = () => {
    dispatch(changeNoticePopup(false));
    setPopupActive(false);
  };

  return (
    <div className="w-full scrollbar-hide">
      {/*....Pop up....*/}
      {popupActive && activePopup && homePageDetails?.pop_up && (
        <Popup data={homePageDetails?.pop_up[0]} handleCross={handleCross} />
      )}
      {/*...Overlay....*/}
      {popupActive && activePopup && homePageDetails?.pop_up && (
        <div
          style={{
            zIndex: 80,
          }}
          className="bg-black opacity-[60%] z-10 fixed top-0 left-0 right-0 bottom-0 w-full h-[100vh]"
        ></div>
      )}
      {homePageDetails && (
        <div className="scrollbar-hide">
          <HeroBanner bannerData={homePageDetails?.banners} />

          {/*...User Activity Card...*/}
          <div className="section-padding bg-primary py-16">
            <div className="max-w-[1400px] mx-auto grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 xl:gap-8 gap-4 ">
              {activityData?.map((item, index) => (
                <UserActivityCard
                  key={index}
                  title={item?.title}
                  info={item?.info}
                  image={item?.image}
                  link={item?.link}
                />
              ))}
            </div>
          </div>

          {/*....Blood Lions Intro Section...*/}
          <div className="section-padding">
          <div className="w-full py-[50px]  max-w-[1400px] mx-auto">
            <h2 className="font-roboto font-bold text-2xl text-start text-secondary mb-4">
              Introduction of T.U Lions Blood Transfusion And Research Centre
            </h2>

            <div className="grid lg:grid-cols-3 grid-cols-1 xl:gap-8 gap-4 ">
              <div className="lg:col-span-2 col-span-full lg:mb-0 mb-6">
                <BloodLionsIntro
                  image={homePageDetails?.about_us?.image}
                  description={homePageDetails?.about_us?.details}
                />
              </div>
              <div className="col-span-1">
                <div className="w-full]">
                  <h3 className="text-start pb-[10px] text-[#5F666C] font-medium">
                    Upcoming Events
                  </h3>
                  <UpcomingEventCard
                    image={
                      homePageDetails?.events?.image
                        ? homePageDetails?.events?.image
                        : logo
                    }
                    title={homePageDetails.events?.title}
                    description={homePageDetails?.events?.excerpt}
                  />
                </div>
              </div>
            </div>
          </div>
          </div>
          {/*.....Blood Inventory...*/}
          <div className="w-full py-[50px] section-padding">
            <div className="max-w-[1400px] mx-auto">
              <p className="font-roboto font-extrabold text-2xl text-center text-[#00539B]">
                TU Lions Blood Bank Inventory
              </p>

              <div className="w-full flex items-center justify-center lg:justify-center gap-[40px] lg:gap-[30px] flex-wrap mt-[30px] pb-10 ">
                {bloodData?.blood_group?.map((item, index) => (
                  <BloodInventoryCircle
                    key={index}
                    bloodType={item.bloodType}
                    numberOfPint={item.numberOfPint}
                  />
                ))}
              </div>
              <div className="text-[#777777] text-center mt-[10px] lg:mt-[0px]">
                {info?.generalSettings?.blood_bank_inventory &&
                  parse(info?.generalSettings?.blood_bank_inventory)}
              </div>
            </div>
          </div>

          {/*....News and Events...*/}
          {/* {homePageDetails?.blogs?.length > 0 && (
            <div className="w-full px-[20px] lg:px-[100px] w-full mt-[20px]">
              <p className="font-roboto font-extrabold text-2xl text-center text-[#00539B]">
                News and Events
              </p>

              <div className="w-full flex items-center justify-center flex-wrap gap-[40px] mt-[30px] ">
                {homePageDetails?.blogs?.length > 0 &&
                  homePageDetails?.blogs?.map((item, index) => (
                    <NewsCard
                      key={index}
                      type="news"
                      newsTitle={item.title}
                      description={item.excerpt}
                      date={item.updated_at}
                      image={item.image}
                      slug={item.slug}
                    />
                  ))}
              </div>
            </div>
          )} */}

          {/*...Discover How to Donate...*/}
          <div className="w-full section-padding bg-primary text-white">
            <DiscoverHowToDonate />
          </div>

          {/*....Real story and Real People....*/}
          <div className="w-full section-padding mt-[40px]">
            <RealStory events={homePageDetails?.events} />
          </div>

          {/*....Our Reach...*/}
          <div className="bg-[#F9F9F9] w-full flex flex-col items-center gap-[25px] mt-[50px] py-[60px] ">
            <p className="font-roboto font-extrabold text-2xl text-start text-[#00539B]">
              Our Reach
            </p>

            <p>
              Data from 02 Feb 2023 - 28 Apr 2023 <br />
              Updated on 2023/04/28
            </p>

            <div className="w-full flex flex-col lg:flex-row justify-center gap-[20px] lg:gap-[70px] px-[20px]">
              <QuantityBtn
                quantity={homePageDetails?.total_donors}
                text="Donors Registered"
              />
              <QuantityBtn
                quantity={homePageDetails?.total_bloodunits}
                text="Blood units collected"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Root;
