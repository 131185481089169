import React from 'react'
import { lions_logo } from '../assets'
import { IoCalendarOutline } from 'react-icons/io5'
import parse from "html-react-parser";
import { Link } from 'react-router-dom';

const EventCard = ({ index,image, title,date, slug,excerpt }) => {
  return (
    <div className="latest-blog rounded-xl overflow-hidden" key={index}>
    <figure className="h-[220px] bg-slate-500">
      <img
        src={image ? image : lions_logo}
        alt={title}
        className="h-full w-full object-cover object-center"
      />
    </figure>
    <article className="p-4 border border-solid border-gray rounded-br-xl rounded-bl-xl min-h-[202px]">
      <h2 className="font-semibold text-xl line-clamp-2 mb-2">
        {title}
      </h2>
      <span className="flex gap-2 items-center text-gray-500 mb-3 text-sm">
        <IoCalendarOutline className="text-primary" /> {date}
      </span>
      <div className="line-clamp-2">
        {excerpt && parse(excerpt)}
      </div>
      <Link to={`/event-details/${slug}`} className="text-primary hover:underline">
        read more
      </Link>
    </article>
  </div>
  )
}

export default EventCard