import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import { Footer, Navbar } from "./components";
import {
  AboutUs,
  AllMembers,
  Blogs,
  BloodCenter,
  BoardDirectors,
  ContactUs,
  Donors,
  EventDetails,
  Events,
  FindDonor,
  FounderMembers,
  Gallery,
  HostBloodDrive,
  IndividualBlog,
  IndividualGallery,
  News,
  NewsDetails,
  PlaceOrder,
  RegisterAsDonor,
  ResearchPage,
  Root,
  ScheduleBlooodDonation,
  VolunteerWithUs,
} from "./routes";
import { useEffect, useState } from "react";
import axiosBaseURL from "./utils/axios";
// import { useSelector } from "react-redux";
import Video from "./routes/video-list";

function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return null;
}

function App() {
  const [members, setMembers] = useState([]);
  const [siteInfo, setSiteInfo] = useState();
  const [founderMembers, setFounderMembers] = useState([]);
  const [boardMembers, setBoardMembers] = useState([]);

  // Fetching Site Info
  const fetchSiteInfo = async () => {
    try {
      await axiosBaseURL.get("/site-info").then((res) => {
        if (res?.data?.data) {
          setSiteInfo(res?.data?.data);
          return;
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  // Fetching Board Members
  const fetchMembers = async () => {
    try {
      await axiosBaseURL.get("/team-categories").then((res) => {
        // console.log(res?.data?.length)
        if (res?.data) {
          setMembers(res?.data);
          return;
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchMembers();
    fetchSiteInfo();
  }, []);

  useEffect(() => {
    if (Object.keys(members)?.length > 0) {
      const bod = members?.bod;
      const foundMember = members?.founder_members;
      setBoardMembers(bod.sort((a, b) => a.order_list - b.order_list));
      setFounderMembers(
        foundMember.sort((a, b) => a.order_list - b.order_list)
      );
    }
  }, [members]);

  return (
    <div className="App">
      {/* <ScrollToTop /> */}
      <Navbar
        boardMembers={boardMembers}
        founderMembers={founderMembers}
        info={siteInfo}
      />
      <Routes>
        <Route path="/" element={<Root info={siteInfo} />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/donors" element={<Donors />} />
        <Route path="/blood-center" element={<BloodCenter />} />
        <Route path="/board-directors/:slug" element={<BoardDirectors />} />
        <Route path="/founder-members/all-members" element={<AllMembers />} />
        <Route path="/founder-members/:slug" element={<FounderMembers />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/gallery/:slug" element={<IndividualGallery />} />
        <Route path="/gallery/videos" element={<Video />} />
        <Route path="/news" element={<News />} />
        <Route path="/events" element={<Events />} />
        <Route path="/news-details/:slug" element={<NewsDetails />} />
        <Route path="/event-details/:slug" element={<EventDetails />} />
        <Route path="/register-as-donor" element={<RegisterAsDonor />} />
        <Route path="/find-donor" element={<FindDonor />} />
        <Route path="/place-order" element={<PlaceOrder />} />
        <Route path="/volunteer-with-us" element={<VolunteerWithUs />} />
        <Route path="/host-blood-drive" element={<HostBloodDrive />} />
        <Route
          path="/schedule-blood-donation"
          element={<ScheduleBlooodDonation />}
        />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blogs/:slug" element={<IndividualBlog />} />
        <Route path="/contact-us" element={<ContactUs info={siteInfo} />} />
        <Route path="/research" element={<ResearchPage />} />

      </Routes>
      <Footer info={siteInfo} />
    </div>
  );
}

export default App;
