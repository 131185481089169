export const whereDidYouHear = [
    {
        name: "website",
        id: "website"
    },
    {
        name: "Social Media",
        id: "Social Media"
    },
    {
        name: "Newspaper",
        id: "Newspaper"
    },
    {
        name: "from person",
        id: "from person"
    },
]