import React from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { IoCalendarOutline } from "react-icons/io5";
import { lions_logo } from "../assets";

const RecentBlogCard = ({ index,image, title, created_at, slug,excerpt }) => {
  return (
    <div className="latest-blog rounded-xl overflow-hidden" key={index}>
      <figure className="h-[220px] bg-slate-500">
        <img
          src={image ? image : lions_logo}
          alt={title}
          className="h-full w-full object-cover object-center"
        />
      </figure>
      <article className="p-4 border border-solid border-gray rounded-br-xl rounded-bl-xl min-h-[202px]">
        <h2 className="font-semibold text-xl line-clamp-2 mb-2">
          {title}
        </h2>
        <span className="flex gap-2 items-center text-gray-500 mb-3 text-sm">
          <IoCalendarOutline className="text-primary" /> {created_at}
        </span>
        <div className="line-clamp-2">
          {excerpt && parse(excerpt)}
        </div>
        <Link to={`/blogs/${slug}`} className="text-primary hover:underline">
          read more
        </Link>
      </article>
    </div>
  );
};

export default RecentBlogCard;
