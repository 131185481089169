import React from "react";
import { Link } from "react-router-dom";
import { changeNavState } from "../redux/navSlice";
import { useDispatch } from "react-redux";
import { GiWaterDrop } from "react-icons/gi";

const UserActivityCard = ({ title, info, image, link, index }) => {
  const dispatch = useDispatch();

  return (
    <Link
      to={`${link}`}
      className=" w-full text-white"
      key={index}
      onClick={() => {
        dispatch(changeNavState(5));
      }}
    >
      <div className="activity-box text-left flex xl:gap-6 gap-4 items-start md:flex-nowrap flex-wrap ">
        <div className="relative inline-block flex-none">
          <GiWaterDrop className="text-white w-[100px] h-[100px]" />
          <figure className="absolute w-[40px] h-[40px] bottom-[12px] right-[28px]">
            <img
              src={image}
              className="w-full h-full object-fit "
              alt="activityCardImage"
            />
          </figure>
        </div>

        <article className="font-roboto">
          <h3 className="font-extrabold text-2xl mb-3">{title}</h3>
          <p className="text-[16px] font-medium leading-7 ">{info}</p>
        </article>
      </div>
    </Link>
  );
};

export default UserActivityCard;
