import React from "react";
import { MdOutlineCall } from "react-icons/md";
import { FaBusinessTime } from "react-icons/fa";
import { CiMail } from "react-icons/ci";
import { GoLocation } from "react-icons/go";
import { Link } from "react-router-dom";
import parse from "html-react-parser";


const ContactCard = ({ info }) => {
  return (
    <div className="w-full lg:w-[400px] bg-[#FFFFD3] flex flex-col items-start gap-[30px] p-[30px] ">
      <div className="flex gap-[10px] text-start">
        <div>
          <GoLocation className="text-[#E99689] font-bold mt-[5px]" />
        </div>
        <div>
          <p>Address</p>
          <p>{info?.contact_address}</p>
        </div>
      </div>

      <div className="flex gap-[10px] text-start">
        <div>
          <MdOutlineCall className="text-[#E99689] font-bold mt-[5px]" />
        </div>
        <div>
          <p>Phone:</p>
          {info?.contact_mobile?.map((item,index)=><Link to={item} key={index} className="block">{item}</Link>)}
        </div>
      </div>

      <div className="flex gap-[10px] text-start">
        <div>
          <FaBusinessTime className="text-[#E99689] font-bold mt-[5px]" />
        </div>
        <div className="office-li">
          <p>Office Hour</p>
          {info?.contact_working_hours && parse(info?.contact_working_hours)}
        </div>
      </div>

      <div className="flex gap-[10px] text-start">
        <div>
          <CiMail className="text-[#E99689] font-bold mt-[5px]" />
        </div>
        <div>
          <p>Mail</p>
          <a href={`mailto:${info?.contact_email}`}><p>{info?.contact_email}</p></a>
        </div>
      </div>
    </div>
  );
};

export default ContactCard;
