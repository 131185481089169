import React, { useEffect, useState } from "react";
import { Loader, PageBanner } from "../components";
import { tuLionsOffice } from "../assets";
import axiosBaseURL from "../utils/axios";
import { Helmet } from "react-helmet";
import parse from "html-react-parser";

const AboutUs = () => {
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState();
  const [vision, setVision] = useState();
  // Fetch About Us Details
  const fetchDetails = async () => {
    setLoading(true);
    try {
      await axiosBaseURL.get(`/about-us`).then((res) => {
        if (res?.data?.data) {
          setLoading(false);
          setDetails(res.data.data);
          return;
        }
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const fetchVision = async () => {
    setLoading(true);
    try {
      await axiosBaseURL.get(`/about-info`).then((res) => {
        if (res?.data) {
          setLoading(false);
          setVision(res?.data);

          return;
        }
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDetails();
    fetchVision();
  }, []);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>About Us</title>
      </Helmet>
      {console.log(details)}
      <PageBanner image={details?.image} title="About Us" />
      <div className="about-section py-16 text-left">
        {loading ? (
          <div className="w-full flex justify-center items-center">
            <Loader />
          </div>
        ) : details ? (
          <div className="about-info pb-12 section-padding">
            <div className="max-w-[1600px] mx-auto  flex gap-11 lg:flex-nowrap flex-wrap">
              <article>
                <h2 className="heading !text-4xl text-left mb-4 capitalize">
                  {details?.title}
                </h2>
                <div className="text-justify leading-8">
                  {details?.details && parse(details?.details)}
                </div>
              </article>
              <figure className="lg:w-[400px] w-full h-[400px] flex-none rounded-xl overflow-hidden sticky top-0">
                <img
                  src={details?.image}
                  className="w-full h-full object-cover object-center"
                  alt=""
                />
              </figure>
            </div>
          </div>
        ) : (
          <div>
            <p>No Data Found</p>
          </div>
        )}
        {vision && (
          <>
            <div className="about-vision-goals py-16 bg-primary text-white section-padding">
              <div className="max-w-[1600px] mx-auto  ">
                <div className="grid lg:grid-cols-2 grid-cols-1 gap-10">
                  <div className="col-span-1">
                    <div className="about-wrapper">
                      <div className="heading-wrapper">
                        <h2 className="heading-line text-3xl">
                          Our Vision and Mission
                        </h2>
                      </div>
                      <div className="vision-ul">
                        {vision?.mission_goal?.site_goal &&
                          parse(vision?.mission_goal?.site_goal)}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-1">
                    <div className="about-wrapper">
                      <div className="heading-wrapper">
                        <h2 className="heading-line text-3xl">Our Goals</h2>
                      </div>
                      <div className="vision-ul">
                        {vision?.mission_goal?.site_mission &&
                          parse(vision?.mission_goal?.site_mission)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="about-features section-padding ">
              <div className="max-w-[1600px] mx-auto pt-16 ">
                <div className="heading-wrapper !mb-8">
                  <h2 className="heading !text-3xl !text-black capitalize">
                    Our Features & services
                  </h2>
                </div>
                <div className="grid grid-cols-4 gap-8">
                  {vision?.services_data?.map((item, index) => (
                    <div className="xl:col-span-1 md:col-span-2 col-span-full" key={index}>
                      <div className="feature-box p-5 border border-solid border-gray-300 rounded-lg h-full group hover:bg-primary before:border-primary duration-300 hover:text-white">
                        <figure className="w-[50px] h-[50px]">
                          <img
                            src={item?.icon}
                            alt={item?.title}
                            className="h-full w-full object-contain object-center "
                          />
                        </figure>
                        <article className="pt-4">
                          <h3 className="font-medium text-xl mb-2 max-w-[260px] text-balance">
                            {item?.title}
                          </h3>
                          <div className="text-justify leading-7 line-clamp-[10]">
                            {item?.details && parse(item?.details)}
                          </div>
                        </article>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AboutUs;
