import React, { useEffect, useState } from "react";
import axiosBaseURL from "../utils/axios";
import { GalleryCard, Loader, PageBanner } from "../components";
import { galleryBanner } from "../assets";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const Gallery = () => {
  const [loading, setLoading] = useState(false);
  const [galleryCategory, setGalleryCategory] = useState([]);
  const [audioVideo, setAudioVideo] = useState("");
  // Fetching Galllery Category
  const fetchGalleryCategory = async (slug) => {
    setLoading(true);
    try {
      await axiosBaseURL.get(`/gallery-categories`).then((res) => {
        if (res?.data?.data?.length > 0) {
          setGalleryCategory(res?.data?.data);
          setLoading(false);
          return;
        }
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const fetchVideoAudio = async () => {
    setLoading(true);
    try {
      await axiosBaseURL.get(`/video-audio`).then((res) => {
        if (res?.data) {
          setAudioVideo(res?.data);
          setLoading(false);
          return;
        }
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGalleryCategory();
    fetchVideoAudio();
  }, []);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Gallery</title>
        <meta name="description" content="" />
      </Helmet>

      <PageBanner title="Gallery" overlay="bg-[#00539B]" hide={true} />
      {/* <p className="text-[#00539B] text-xl text-center font-bold text-lg mt-[30px]">
        Gallery
      </p> */}
      <div className="section-padding">
        <div className="video-audio-section max-w-[1720px] mx-auto py-10 lg:px-5 ">
          {loading ? (
            <div className="w-full flex justify-center items-center">
              <Loader />
            </div>
          ) : (
            Object.keys(audioVideo)?.length > 0 && (
              <>
                <div className="grid lg:grid-cols-3 grid-col-2 xl:gap-10 gap-6">
                  {audioVideo?.video?.length > 0 && (
                    <div className="col-span-2">
                      <div className="video-wrapper">
                        <h4 className="text-[#00539B] text-xl text-left font-semibold mb-5">
                          Video Section
                        </h4>
                        <div className="video-group">
                          <div className="mx-auto grid w-full md:grid-flow-col md:grid-rows-2 grid-cols-1 gap-5 xl:min-h-[570px] min-h-[400px]">
                            {audioVideo?.video
                              ?.slice(0, 3)
                              .map((item, index) => (
                                <div
                                  className={`${
                                    index % 3 === 0
                                      ? "md:col-span-8 md:row-span-2 col-span-1"
                                      : "col-span-1"
                                  }`}
                                  key={index}
                                >
                                  <div className="iframe-wrapper md:h-full h-[240px] w-full">
                                    <iframe
                                      src={item.url}
                                      title="YouTube video player"
                                      className="h-full w-full"
                                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                      referrerPolicy="strict-origin-when-cross-origin"
                                      allowFullScreen
                                    ></iframe>
                                  </div>
                                </div>
                              ))}
                          </div>
                          <div className="btn-wrapper pt-8 text-center">
                            <Link
                              className="bg-[#D4432F] rounded-[8px] px-[36px] py-[13px] text-[16px] font-[400] text-white inline-block hover:bg-opacity-75"
                              to="/gallery/videos"
                            >
                              View All Videos
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {audioVideo?.audio?.length > 0 && (
                    <>
                      <div className="lg:col-span-1 col-span-2">
                        <div className="audio-wrapper h-full">
                          <h4 className="text-[#00539B] text-xl text-left font-semibold mb-5">
                            Audio Section
                          </h4>
                          <ul className="text-left border-solid border-secondary border xl:p-7 p-4 border-opacity-20 lg:h-[100%]  xl:max-h-[570px] md:max-h-[400px] overflow-y-auto">
                            {audioVideo?.audio?.map((item, index) => (
                              <li
                                key={index}
                                className="[&:not(:last-child)]:mb-3 [&:not(:last-child)]:pb-4 [&:not(:last-child)]:border-b border-solid border-secondary border-opacity-20"
                              >
                                <p className="capitalize mb-1">{item.title}</p>
                                <audio controls className="w-full bg-[#f1f3f4]">
                                  <source
                                    src={item.audio_url}
                                    type="audio/mpeg"
                                  />
                                </audio>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </>
            )
          )}
          {galleryCategory?.length > 0 && (
            <>
              <div className="gallery-section pt-10">
                <h4 className="text-[#00539B] text-xl text-left font-semibold mb-5">
                  Gallery Section
                </h4>
                <GalleryCard GalleryData={galleryCategory} />
              </div>
            </>
          )}
        </div>
      </div>
      {/* <div className="flex flex-col lg:flex-row items-center justify-center gap-[30px] flex-wrap py-[40px] px-[20px] lg:px-[50px] scrollbar-hide ">
        {loading ? (
          <div className="w-full flex justify-center items-center">
            <Loader />
          </div>
        ) : galleryCategory?.gallery?.length > 0 ? (
          galleryCategory.map((item, index) => (
            <GalleryCard
              key={index}
              image1={item.gallery[0]?.image}
              // image2={item.gallery[1]?.image}
              // image3={item.gallery[2]?.image}
              slug={item.slug}
              title={item.name}
            />
          ))
        ) : (
          <div>No Data Found</div>
        )}
      </div> */}
    </div>
  );
};

export default Gallery;
