import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Dropdown, Loader, PageBanner } from "../components";
import axiosBaseURL from "../utils/axios";

const PlaceOrder = () => {
  const [selectedBloodGroup, setSelectedBloodGroup] = useState("");
  const [bloodGroupTypes, setBloodGroupTypes] = useState([]);
  const [needFreshBlood, setNeedFreshBlood] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [noOfPint, setNoOfPint] = useState();
  const [admittedHospital, setAdmittedHospital] = useState("");
  const [contactNumber, setContactNumber] = useState();
  const [message, setMessage] = useState("");
  const [healthProblem, setHealthProblem] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [age, setAge] = useState();
  const [type, setType] = useState("request_blood");

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  // Handle Blood Group change
  const handleBloodGroupChange = (event) => {
    setSelectedBloodGroup(event.target.value);
  };

  // Fetch Blood Types
  const fetchBloodTypes = async () => {
    try {
      await axiosBaseURL.get(`/blood-groups`).then((res) => {
        if (res?.data?.data?.length > 0) {
          setBloodGroupTypes(res?.data?.data);
          return;
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchBloodTypes();
  }, []);

  // Handle Submit

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    if (
      fullName &&
      contactNumber &&
      email &&
      selectedBloodGroup &&
      noOfPint &&
      admittedHospital &&
      needFreshBlood &&
      healthProblem &&
      message &&
      selectedFile &&
      age
    ) {
      if (contactNumber.length !== 10) {
        setErrorMessage("Length of Contact number must be 10.");
        return;
      } else {
        console.log(selectedFile);
        const formData = new FormData();
        formData.append("name", fullName);
        formData.append("age", age);
        formData.append("patient_name", fullName);
        formData.append("phone", contactNumber);
        formData.append("email", email);
        formData.append("subject", healthProblem);
        formData.append("message", message);
        formData.append("patient_bloodgroup", selectedBloodGroup);
        formData.append("blood_qty", noOfPint);
        formData.append("admitted_hospital", admittedHospital);
        formData.append("need_freshblood", needFreshBlood);
        formData.append("image", selectedFile);
        // formData.append('enquiry',type);

        try {
          setLoading(true);
          await axiosBaseURL
            .post("/submit-enquiry", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((res) => {
              console.log(res);
              setLoading(false);
              setSuccessMessage(res.data.message);
              setFullName("");
              setContactNumber("");
              setEmail("");
            });
        } catch (error) {
          console.log(error);
          setLoading(false);
          setSuccessMessage("");
          setErrorMessage(error.message);
        }
      }
    } else {
      setErrorMessage("Fill All the fields");
    }
  };

  return (
    <div className="">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Request Blood</title>
        <meta name="description" content="" />
      </Helmet>
      <PageBanner title="Place Order" />

      <div className="w-full flex justify-center items-center">
        <div className="w-full lg:w-[80%] flex flex-col items-center px-[20px] shadow-lg mb-[80px] pb-[40px]">
          {/*...Register as donor text div....*/}
          <div className="w-full py-[40px] border-2 border-x-0 border-t-0 border-b-[#CC8282]">
            <p className="text-[#00539B] text-lg font-bold">
              Request Blood Here!
            </p>
          </div>

          <form
            onSubmit={handleSubmit}
            className="w-full md:w-[600px] mt-[30px] flex flex-col gap-[15px]"
          >
            <label className="flex flex-col items-start gap-[5px]">
              <p className="font-bold">Patient Name</p>
              <input
                type="text"
                placeholder="Enter Your Name"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                className="w-full border border-slate-300 py-[7px] px-[5px] rounded-[6px]"
              />
            </label>

            <label className="flex flex-col items-start gap-[5px]">
              <p className="font-bold">Patient Age</p>
              <input
                type="number"
                placeholder="Enter Your Age"
                value={age}
                onChange={(e) => setAge(e.target.value)}
                className="w-full border border-slate-300 py-[7px] px-[5px] rounded-[6px]"
              />
            </label>

            <label className="flex flex-col items-start gap-[5px]">
              <p className="font-bold">Contact Number</p>
              <input
                type="number"
                placeholder="Mobile no."
                value={contactNumber}
                onChange={(e) => setContactNumber(e.target.value)}
                className="w-full border border-slate-300 py-[7px] px-[5px] rounded-[6px]"
              />
            </label>

            <label className="flex flex-col items-start gap-[5px]">
              <p className="font-bold">Email address</p>
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full border border-slate-300 py-[7px] px-[5px] rounded-[6px]"
              />
            </label>

            <label className="flex flex-col items-start gap-[5px]">
              <p className="font-bold">Select Your Blood Type</p>
              <Dropdown
                dropdownTitle="Blood Group"
                data={bloodGroupTypes}
                selectedData={selectedBloodGroup}
                onChange={handleBloodGroupChange}
              />
            </label>

            <label className="flex flex-col items-start gap-[5px]">
              <p className="font-bold">Number of Pint Required</p>
              <input
                type="number"
                placeholder="e.g. 1"
                value={noOfPint}
                onChange={(e) => setNoOfPint(e.target.value)}
                className="w-full border border-slate-300 py-[7px] px-[5px] rounded-[6px]"
              />
            </label>

            <label className="flex flex-col items-start gap-[5px]">
              <p className="font-bold">Admitted Hospital</p>
              <input
                type="text"
                placeholder="e.g. Patan Hospital"
                value={admittedHospital}
                onChange={(e) => setAdmittedHospital(e.target.value)}
                className="w-full border border-slate-300 py-[7px] px-[5px] rounded-[6px]"
              />
            </label>

            <label className="flex flex-col items-start gap-[5px]">
              <p className="font-bold">Upload Document Image</p>
              <input
                type="file"
                onChange={handleFileSelect}
                placeholder="choose file"
                className="w-full border border-slate-300 py-[7px] px-[5px] rounded-[6px]"
              />
            </label>

            <div className="flex flex-col items-start gap-[10px]">
              <p className="font-bold">Need fresh blood?</p>
              <div className="flex items-center gap-[50px]">
                <div className="flex gap-[5px]">
                  <input
                    type="radio"
                    id="yes"
                    name=""
                    value=""
                    checked={needFreshBlood === "yes"}
                    onChange={() => setNeedFreshBlood("yes")}
                  />
                  <label htmlFor="yes">Yes</label>
                </div>
                <div className="flex gap-[5px]">
                  <input
                    type="radio"
                    id="no"
                    name=""
                    value=""
                    checked={needFreshBlood === "no"}
                    onChange={() => setNeedFreshBlood("no")}
                  />
                  <label htmlFor="no">No</label>
                </div>
              </div>
            </div>

            <label className="flex flex-col items-start gap-[5px]">
              <p className="font-bold">Health Problem</p>
              <input
                type="text"
                placeholder="Write down patient problems"
                value={healthProblem}
                onChange={(e) => setHealthProblem(e.target.value)}
                className="w-full border border-slate-300 py-[7px] px-[5px] rounded-[6px]"
              />
            </label>

            <label className="flex flex-col items-start gap-[5px]">
              <p className="font-bold">Any Messages</p>
              <textarea
                placeholder="Write messages..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className="w-full h-[100px] border border-slate-300 py-[7px] px-[5px] rounded-[6px]"
              />
            </label>

            {errorMessage && (
              <div>
                <p className="text-red-500">*{errorMessage}</p>
              </div>
            )}

            {loading && !successMessage ? (
              <Loader />
            ) : !loading && successMessage ? (
              <div>
                <p className="text-green-400 bg-white px-[10px] py-[6px] rounded-xl shadow-xl">
                  {successMessage}
                </p>
              </div>
            ) : (
              <button
                type="submit"
                className="w-max bg-[#D42F32] px-[20px] py-[7px] text-white rounded-lg"
              >
                Submit
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default PlaceOrder;
