import React from "react";
import { MdOutlineCall } from "react-icons/md";
import { FiMail } from "react-icons/fi";

import { Link } from "react-router-dom";
// import { clockIcon } from "../assets";
import { IoLocationOutline } from "react-icons/io5";
import parse from "html-react-parser";
import { useDispatch } from "react-redux";
import { changeNavState } from "../redux/navSlice";
import { nanoid } from "@reduxjs/toolkit";

const Footer = ({ info }) => {
  const dispatch = useDispatch();
  const quickLinks = [
    {
      title: "Home",
      link: "/",
      navId:0,
    },
    {
      title: "Place order",
      link: "/place-order",      navId:null,

    },
    {
      title: "News and Events",
      link: "/news",      navId:3,

    },
    {
      title: "Find Blood",
      link: "/find-donor",      navId:2,

    },
    {
      title: "Gallery",
      link: "/gallery",      navId:20,

    },
    {
      title: "Contact us",
      link: "/contact-us",      navId:null,

    },
  ];


  return (
    <div className="w-full footer">
      <div className="bg-[#031F37] w-full flex flex-col lg:flex-row lg:justify-evenly md:gap-[30px] px-[20px] lg:px-[50px] py-[60px] gap-y-14">
        {/*...Map section...*/}
        <div className="w-full lg:w-[300px] flex flex-col gap-[30px]">
          <p className="text-start text-white text-lg font-medium">
            TU Lions Blood Transfusion & Research Centre, Kirtipur M7GQ+MG6,
            Kirtipur 44600
          </p>

          <div className="w-full">
            <iframe
              src={
                info?.map_url
                  ? info?.map_url
                  : `https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d226871.59456421918!2d85.25230593893403!3d27.60674336597018!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb19661121686b%3A0xcef24f09ffb074bf!2sTU%20Lions%20Blood%20Transfusion%20%26%20Research%20Center%20(TU%20Lions%20Blood%20Bank)!5e0!3m2!1sen!2snp!4v1714455820759!5m2!1sen!2snp`
              }
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              className="w-full lg:w-[250px] h-[150px]"
            ></iframe>
          </div>
        </div>

        {/*...Useful Links...*/}
        <div className="flex flex-col items-start gap-[20px] text-white flex-none">
          <p className="text-lg font-medium">Navigation Links</p>
          {quickLinks?.map((item, index) => (
            <Link
              key={index}
              to={item.link}
              className="hover:text-primary duration-300" onClick={() => {
                dispatch(changeNavState(item.navId));
              }}
            >
              {item.title}
            </Link>
          ))}
        </div>

        {/*...Office Hours...*/}
        <div className="flex flex-col justify-start gap-[20px] text-white text-start office-li">
          <p className="text-lg font-medium">Office Hours</p>
          <div className="flex items-center gap-[10px]">
            {info?.contact_working_hours && parse(info?.contact_working_hours)}
          </div>
        </div>

        {/*....Contact Us...*/}
        <div className="flex flex-col items-start gap-[20px] text-start text-white ">
          <p className="text-lg font-medium">Contact Us</p>

          <div className="flex items-center gap-[10px]">
            <IoLocationOutline />

            <p>{info?.contact_address}</p>
          </div>

          {info?.contact_mobile?.map((item, index) => (
            <div className="flex items-center gap-[10px]" key={index}>
              <MdOutlineCall />
              <Link
                to={`tel:${item}`}
                className="hover:text-primary duration-300"
              >
                {item}
              </Link>
            </div>
          ))}

          <div className="flex items-center gap-[10px]">
            <FiMail />
            <a
              href={`mailto:${info?.contact_email}`}
              className="hover:text-primary duration-300"
            >
              <p>{info?.contact_email}</p>
            </a>
          </div>
        </div>
      </div>

      {/*....Rights reserved....*/}
      <div className="relative flex flex-col items-center gap-[20px] bg-[#003462] px-[10px] py-[10px] text-center text-white">
        <p>{info?.site_footer_copyright}</p>
      </div>
    </div>
  );
};

export default Footer;
