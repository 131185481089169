import React from "react";
import { Link } from "react-router-dom";
import { lions_logo } from "../assets";

const BlogCard = ({ image, title, description, tags, slug }) => {
  const tagArray = tags?.split(",");

  return (
    <Link
      to={`/blogs/${slug}`}
      className="col-span-1 min-h-[414px] flex flex-col items-start gap-[10px] shadow-lg text-start rounded-lg px-[10px] pb-5 hover:shadow-xl duration-300 group"
    >
        <img
          src={image ? image : lions_logo}
          className="w-full lg:w-[295px] h-[260px] object-cover"
          alt="blogImage"
        />

      <p className="font-bold px-[5px] font-lg group-hover:text-primary duration-300">{title}</p>
      <div
        className="px-[5px] text-[#777777]"
        dangerouslySetInnerHTML={{
          __html:
            description?.length > 150
              ? description.slice(0, 150) + "..."
              : description,
        }}
      />

      <div className="px-[5px] flex items-center gap-[10px] flex-wrap">
        {tagArray?.length > 0 &&
          tagArray.map((item, index) => (
            <div
              key={index}
              className="rounded-full px-[10px] py-[2px] border-2 border-slate-300 text-[#777777]"
            >
              {item}
            </div>
          ))}
      </div>
    </Link>
  );
};

export default BlogCard;
