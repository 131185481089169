import React, { useEffect, useState } from "react";
import { HeroBanner, Loader, NewsCard, PageBanner, RecentBlogCard } from "../components";
import { newsAndEvents } from "../data/newsAndEvents";
import axiosBaseURL from "../utils/axios";
import { Helmet } from "react-helmet";
import EventCard from "../components/EventCard";

const Events = () => {
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);

  const fetchNews = async () => {
    setLoading(true);
    try {
      await axiosBaseURL.get("/events").then((res) => {
        if (res?.data?.data?.length > 0) {
          setEvents(res?.data?.data);
          setLoading(false);
          return;
        }
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNews();
  }, []);

  return (
    <div className="pb-[100px]">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Events</title>
      </Helmet>
      <PageBanner title="Events" />
<div className="section-padding">
      <div className="max-w-[1200px] mx-auto mt-[40px]">
        <p className="font-roboto font-extrabold text-2xl text-center text-[#00539B]">
          Events
        </p>

        <div className="w-full flex flex-col lg:flex-row items-center justify-center flex-wrap gap-[40px] mt-[30px] scrollbar-hide">
          {loading ? (
            <div className="w-full flex justify-center items-center">
              <Loader />
            </div>
          ) : events?.length > 0 ? (
            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8 text-left">
              <>
              {console.log(events)}
                {events?.map((item, index) => (
                // <NewsCard
                //   key={index}
                //   type="event"
                //   newsTitle={item.title}
                //   description={item.excerpt}
                //   date={item.date}
                //   image={item.image}
                //   slug={item.slug}
                // />
                <EventCard key={index} {...item} />
                ))}
              </>
            </div>
          ) : (
            <div>No Data Found</div>
          )}
        </div>
      </div>
      </div>
    </div>
  );
};

export default Events;
