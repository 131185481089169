import React from "react";
import { bloodDonation, kmc, lcif, lmc } from "../assets";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { Navigation, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
// import Swiper and modules styles
import "swiper/css";
import "swiper/css/navigation";
import { useState } from "react";
import axiosBaseURL from "../utils/axios";
import { useEffect } from "react";
import { changeNavState } from "../redux/navSlice";
import { useDispatch } from "react-redux";
// init Swiper:
export const partnerData = [
  {
    img: lcif,
    content: "",
  },
  {
    img: kmc,
    content: "",
  },
  {
    img: lmc,
    content: "",
  },
  {
    img: lcif,
    content: "",
  },
  {
    img: kmc,
    content: "",
  },
  {
    img: lmc,
    content: "",
  },
];

const RealStory = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [partner, setPartner] = useState("");
  const [recentBlogs, setRecentBlogs] = useState([]);

  const fetchPartner = async () => {
    setLoading(true);
    try {
      await axiosBaseURL.get(`/partners`).then((res) => {
        if (res?.data?.data) {
          setPartner(res?.data?.data);
          console.log(partner);
          setLoading(false);

          return;
        }
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const fetchRecentBlogs = async () => {
    setLoading(true);
    try {
      await axiosBaseURL.get(`/recent-blogs`).then((res) => {
        if (res?.data?.data?.length > 0) {
          setRecentBlogs(res?.data?.data);
          console.log(recentBlogs);
          return;
        }
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPartner();
    fetchRecentBlogs();
  }, []);
  console.log(recentBlogs);

  return (
    
    <div className="mx-auto max-w-[1400px] text-left">
      <div className="grid lg:grid-cols-2 grid-cols-1 gap-16">
        <div className="col-span-1">
          <h3 className="font-roboto font-bold text-[24px] text-start text-[#00539B] mb-4">
            News
          </h3>
          {recentBlogs?.slice(0, 1).map((item, index) => (
            <React.Fragment key={index}>
              <div className="font-medium text-lg text-[16px] mb-1">
                <h4>{item?.title}</h4>
              </div>

              <div className="text-[#777777] text-[16px] mb-4 line-clamp-5 text-justify">
                {item?.details && parse(item?.details)}
              </div>
            </React.Fragment>
          ))}

          <Link
            to="/contact-us"
            onClick={() => {
              dispatch(changeNavState(null));
            }}
          >
            <button className="bg-[#D4432F] rounded-[8px] px-[36px] py-[16px] text-[16px] font-[400] text-white ">
              Contact us
            </button>
          </Link>
        </div>

        <div className="col-span-1 partners">
          {/* <img
          src={events?.image || bloodDonation}
          className="w-[450px] object-fit"
          alt="bloodDonationImage"
        /> */}
          {partner?.length > 0 && (
            <div className="heading-wrapper">
              <h4 className="font-roboto font-extrabold text-[24px] text-start text-[#00539B] pb-6">
                Donor Partners
              </h4>
              <Swiper
                spaceBetween={30}
                slidesPerView={2}
                breakpoints={{
                  768: {
                    slidesPerView: 3,
                  },
                  1024: {
                    slidesPerView: 4,
                  },
                }}
                autoplay={{
                  delay: 3000,
                  pauseOnMouseEnter: true,
                }}
                navigation={true}
                modules={[Autoplay, Navigation]}
                className="mySwiper"
              >
                {partner.map((item, index) => (
                  <SwiperSlide key={index} className="flex justify-center">
                    <figure className="w-[140px] h-[140px] p-5 mx-auto">
                      <img
                        src={item.image}
                        alt={item.title}
                        className="w-full h-full object-contain object-center"
                      />
                    </figure>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RealStory;
