import React from "react";

const QuantityBtn = ({ quantity, text }) => {
  return (
    <div style={{
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
    }} className="w-full lg:w-[320px] h-[120px] bg-[#D4432F] flex flex-col items-center justify-center rounded-[8px] text-white">
      <p className="font-medium text-[24px]">{quantity}</p>
      <p className="text-[16px]">{text}</p>
    </div>
  );
};

export default QuantityBtn;
