import React from "react";
import { TbDropletFilled } from "react-icons/tb";

const BloodInventoryCircle = ({ numberOfPint, bloodType }) => {
  return (
    <div>
      <div
        className={`${
          numberOfPint === 0 ? "bg-[#999999]" : "bg-[#D4432F]"
        } relative w-[100px] h-[100px] rounded-full flex justify-center items-center`}
      >
        <TbDropletFilled className="text-[200px] text-white" />
        <div className="absolute top-[50%] left-[50%] transform -translate-x-[50%] -translate-y-[50%] font-medium ">
          <p>{numberOfPint}</p>
          <p>
            pint
          </p>
        </div>
      </div>

      <p className="font-bold text-[25px] pt-[10px] lg:pt-[20px]">{bloodType}</p>
    </div>
  );
};

export default BloodInventoryCircle;
