import React, { useEffect, useState } from "react";
import { logo } from "../assets";
import { Link } from "react-router-dom";
import { AiOutlineMenu } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";
import { navLinks } from "../data/navlLinks";
import { useDispatch, useSelector } from "react-redux";
import { changeNavState } from "../redux/navSlice";
import { AiOutlineYoutube, AiFillTwitterCircle } from "react-icons/ai";
import { TiSocialLinkedinCircular } from "react-icons/ti";
import { FaFacebook } from "react-icons/fa";

const Navbar = ({ boardMembers, founderMembers, info }) => {
  const dispatch = useDispatch();

  const [activeNavItemIndex, setActiveNavItemIndex] = useState();
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [bodMembers, setBodMembers] = useState([]);
  const activeNav = useSelector((state) => state.navState.activeNav);
  // Toggle Mobile menu bar
  const toggleMobileMenu = () => {
    setShowMobileMenu((prev) => !prev);
  };

  useEffect(() => {
    if (boardMembers?.length > 0) {
      const bod = boardMembers;
      setBodMembers(bod);
    }
  }, [boardMembers]);

  return (
    <div className="[box-shadow:-3px_0px_16px_8px_rgb(0_0_0_/_3%)] relative z-10">
      {/*...Overlay..*/}
      {showMobileMenu && (
        <div className="lg:hidden bg-black/25 z-10 fixed w-full h-screen"></div>
      )}

      {/*....Top most section....*/}
      <div className="w-full overflow-x-scroll scrollbar-hide flex justify-between md:gap-[40px] bg-[#D4432F] px-[30px] py-[10px] text-start md:flex-nowrap flex-wrap gap-2">
        <div className="top-links">
          <Link
            to="/place-order"
            className="font-medium text-white text-[12px] lg:text-[14px] mr-4"
            onClick={() => {
              dispatch(changeNavState(null));
            }}
          >
            Donate Blood
          </Link>
          <Link
            to="/host-blood-drive"
            className="font-medium text-white text-[12px] lg:text-[14px]"
            onClick={() => {
              dispatch(changeNavState(null));
            }}
          >
            Book Blood Donation Camp
          </Link>
        </div>
        <div className="top-socials">
          <ul className="flex flex-row gap-3 text-white text-[22px]">
            <li>
              <Link to={info?.fb_url}>
                <FaFacebook />
              </Link>
            </li>
            <li>
              <Link to={info?.twitter_url}>
                <AiFillTwitterCircle />
              </Link>
            </li>
            <li>
              <Link to={info?.youtube_url}>
                <AiOutlineYoutube />
              </Link>
            </li>
            <li>
              <Link to={info?.linkedin_url}>
                <TiSocialLinkedinCircular />
              </Link>
            </li>
          </ul>
        </div>
      </div>

      {/*...Upper Section....*/}
      <div className="flex items-center justify-between px-[20px] lg:px-[40px] py-[20px]">
        {/*...Logo Section.....*/}
        <Link
          to="/"
          className="relative"
          onClick={() => {
            dispatch(changeNavState(0));
          }}
        >
          {/* {console.log(info)} */}
          <img
            src={info?.generalSettings?.website_logo || logo}
            alt="logo"
            className="w-[200px] lg:w-[300px] object-fit"
          />
          <p className="text-[#2313DB] text-[11px] opacity-[70%] max-w-[170px] lg:max-w-[400px] text-start font-medium ml-[30px] lg:ml-[45px] mt-[-5px]">
            For Blood, Platelets, Plasma, Cells, Organ & Tissues
          </p>
        </Link>
        <div className="hidden lg:flex items-center gap-[30px] text-[#D4432F] font-roboto ">
          {/*....Blood donation..*/}
          <Link
            to="https://tulionsbloodbank.org/etender/public/"
            target="_blank"
            className="font-extrabold text-[16px] bg-[#D4432F] rounded-md py-2 px-6 text-white"
          >
            E-Tender
          </Link>
          <Link
            to=""
            className="font-extrabold text-[16px]"
            onClick={() => {
              dispatch(changeNavState(null));
            }}
          >
            Blood For Safe Life
          </Link>

          <Link
            to=""
            className="font-extrabold text-[16px]"
            onClick={() => {
              dispatch(changeNavState(null));
            }}
          >
            Plasma For Safe Life
          </Link>

          <Link
            to=""
            className="font-extrabold text-[16px]"
            onClick={() => {
              dispatch(changeNavState(null));
            }}
          >
            How To Donate
          </Link>

          <Link
            to="/blogs"
            className="font-extrabold text-[16px]"
            onClick={() => {
              dispatch(changeNavState(null));
            }}
          >
            Blogs
          </Link>
        </div>

        {/*...Menu Bar for mobile view...*/}
        <button
          onClick={toggleMobileMenu}
          className="lg:hidden hover:text-gray-300 focus:outline-none cursor-pointer"
        >
          <AiOutlineMenu className="h-6 w-6" />
        </button>
      </div>

      {/*....Lower Section for Destop....*/}
      <div
        onMouseLeave={() => setActiveNavItemIndex(null)}
        className="w-full hidden lg:flex items-center justify-center xl:gap-[20px] md:gap-[5px] flex-wrap pb-[20px] px-[20px]"
      >
        {navLinks?.map((item, index) => (
          <div
            key={index}
            className={`relative flex flex-col items-center ${
              activeNav === index &&
              "text-[#D4432F] border-2 border-t-0 border-l-0 border-r-0 border-b-[#D4432F]"
            } ${item?.subCategory?.length > 0 && "group"}`}
            onMouseEnter={() => setActiveNavItemIndex(index)}
            onClick={() => {
              dispatch(changeNavState(index));
            }}
          >
            <Link
              to={item?.link ? item?.link : "#"}
              className={`${
                activeNavItemIndex === index && "text-[#D42F32]"
              } font-medium px-[10px] py-[10px] `}
            >
              {item.title}
            </Link>
            {item?.subCategory?.length > 0 && (
              <div
                onMouseLeave={() => setActiveNavItemIndex(null)}
                className="hidden group-hover:flex transition-all duration-1000 w-max absolute top-[30px] left-[50%] transform -translate-x-[50%] bg-[#D42F32] flex-col group-hover:py-[10px] text-white text-start font-[16px] z-30"
              >
                {item.subCategory.map((li, i) => (
                  <Link
                    key={i}
                    to={li.link}
                    onClick={() => {
                      dispatch(changeNavState(index));
                    }}
                    className="py-2 px-4 hover:bg-[#df5254]"
                  >
                    {li.title}
                  </Link>
                ))}
              </div>
            )}
          </div>
        ))}

        {/*.... Board Members..*/}
        {bodMembers?.length > 0 && (
          <div
            className={`relative flex flex-col items-center   group ${
              activeNav === 50 &&
              "text-[#D4432F] border-2 border-t-0 border-l-0 border-r-0 border-b-[#D4432F]"
            }`}
            onMouseEnter={() => setActiveNavItemIndex(50)}
            onClick={() => {
              dispatch(changeNavState(50));
            }}
          >
            <p
              className={`${
                activeNavItemIndex === 50 ? "text-[#D42F32]" : "text-black"
              } font-medium px-[10px] py-[10px] pointer-events-none `}
            >
              Board of Directors
            </p>
            {bodMembers?.length > 0 && (
              <div
                onMouseLeave={() => setActiveNavItemIndex(null)}
                className="hidden group-hover:flex transition-all duration-1000 w-max absolute top-[30px] left-[50%] transform -translate-x-[50%] bg-[#D42F32] flex-col group-hover:py-[10px] text-white text-start font-[16px] z-30"
              >
                {bodMembers?.map((item, index) => (
                  <Link
                    key={index}
                    to={`/board-directors/${item.slug}`}
                    className="py-2 px-4 hover:bg-[#df5254]"
                    onClick={() => {
                      dispatch(changeNavState(50));
                    }}
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
            )}
          </div>
        )}
        {/*...Founder Members..*/}
        {
          <div
            className={`relative flex flex-col items-center group ${
              activeNav === 49 &&
              "text-[#D4432F] border-2 border-t-0 border-l-0 border-r-0 border-b-[#D4432F]"
            }`}
            onMouseEnter={() => setActiveNavItemIndex(49)}
          >
            <p
              className={`${
                activeNavItemIndex === 49 ? "text-[#D42F32]" : "text-black"
              } font-medium px-[10px] py-[10px] pointer-events-none`}
            >
              Founder Members
            </p>
            {founderMembers?.length > 0 && (
              <div
                onMouseLeave={() => setActiveNavItemIndex(null)}
                className="hidden group-hover:flex transition-all duration-1000 w-max absolute top-[30px] left-[50%] transform -translate-x-[50%] bg-[#D42F32] flex-col group-hover:py-[10px] text-white text-start font-[16px] z-30"
              >
                <Link
                  to={`/founder-members/all-members`}
                  className="py-2 px-4 hover:bg-[#df5254]"
                  onClick={() => {
                    dispatch(changeNavState(49));
                  }}
                >
                  All Members
                </Link>
                {founderMembers?.map((item, index) => (
                  <Link
                    key={index}
                    to={`/founder-members/${item.slug}`}
                    className="py-2 px-4 hover:bg-[#df5254]"
                    onClick={() => {
                      dispatch(changeNavState(49));
                    }}
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
            )}
          </div>
        }

        {/*...Gallery...*/}
        <div
          className={`relative flex flex-col items-center ${
            activeNav === 20 &&
            "text-[#D4432F] border-2 border-t-0 border-l-0 border-r-0 border-b-[#D4432F]"
          }`}
          onMouseEnter={() => setActiveNavItemIndex(20)}
          onClick={() => {
            dispatch(changeNavState(20));
          }}
        >
          <Link
            to={`/gallery`}
            className={`${
              activeNavItemIndex === 20 && "text-[#D42F32]"
            } font-medium px-[10px] py-[10px] `}
          >
            Gallery
          </Link>
        </div>
      </div>

      {/*....Lower Section for Mobile responsive....*/}
      {showMobileMenu && (
        <div
          style={{
            zIndex: 99,
          }}
          className="w-[80%] h-[100%] lg:hidden fixed top-[0px] right-0 flex flex-col gap-[10px] overflow-y-scroll scrollbar-hide bg-white"
        >
          <div className="logo p-2 border-solid border-b border-gray-300">
            <figure>
              <img
                src={info?.generalSettings?.website_logo || logo}
                alt="logo"
                className="w-[200px] lg:w-[300px] object-fit"
              />
            </figure>
          </div>
          {/*...NavLinks(mobile)...*/}
          <div className="px-[20px] pb-3 flex flex-col items-start">
            {navLinks.map((item, index) => (
              <div className="">
                {item?.link ? (
                  <Link
                    to={item.link}
                    key={index}
                    onClick={() => setShowMobileMenu(false)}
                    className="block px-3 py-2 rounded-md text-base font-medium hover:text-gray-300"
                  >
                    {item.title}
                  </Link>
                ) : (
                  <div
                    className="relative flex flex-col"
                    onClick={() => setActiveNavItemIndex(111)}
                  >
                    <p className="block px-3 py-2 rounded-md text-start font-medium hover:text-gray-300">
                      {item.title}
                    </p>
                    {activeNavItemIndex === 111 &&
                      item?.subCategory?.length > 0 && (
                        <div
                          onMouseLeave={() => setActiveNavItemIndex(null)}
                          className="flex flex-col gap-[20px] pt-0 py-[10px] px-[20px] text-start text-slate-400 font-[16px] z-30"
                        >
                          {item.subCategory.map((li, i) => (
                            <Link
                              key={i}
                              to={li.link}
                              onClick={() => {
                                dispatch(changeNavState(111));
                                setShowMobileMenu(false);
                              }}
                            >
                              {li.title}
                            </Link>
                          ))}
                        </div>
                      )}
                  </div>
                )}
              </div>
            ))}

            {/*.... Board Members(mobile)..*/}
            {bodMembers?.length > 0 && (
              <div
                className="relative flex flex-col"
                onClick={() => setActiveNavItemIndex(50)}
              >
                <p className="block px-3 py-2 rounded-md text-start font-medium hover:text-gray-300">
                  BOD
                </p>
                {activeNavItemIndex === 50 && (
                  <div
                    onMouseLeave={() => setActiveNavItemIndex(null)}
                    className="flex flex-col gap-[20px] pt-0 py-[10px] px-[20px] text-start text-slate-400 font-[16px] z-30"
                  >
                    {bodMembers.map((item, index) => (
                      <Link
                        key={index}
                        to={`/board-directors/${item.slug}`}
                        onClick={() => {
                          dispatch(changeNavState(50));
                          setShowMobileMenu(false);
                        }}
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            )}
            {/*...Founder Members(Mobile)..*/}
            {founderMembers?.length > 0 && (
              <div
                className="relative flex flex-col"
                onClick={() => setActiveNavItemIndex(49)}
              >
                <p className="block px-3 py-2 rounded-md text-start font-medium hover:text-gray-300">
                  Founder Members
                </p>
                {activeNavItemIndex === 49 && (
                  <div
                    onMouseLeave={() => setActiveNavItemIndex(null)}
                    className="flex flex-col gap-[20px] pt-0 py-[20px] px-[10px] text-start text-slate-400 font-[16px] z-30"
                  >
                    <Link
                      to={`/founder-members/all-members`}
                      onClick={() => {
                        dispatch(changeNavState(49));
                        setShowMobileMenu(false);
                      }}
                    >
                      All Members
                    </Link>
                    {founderMembers.map((item, index) => (
                      <Link
                        key={index}
                        to={`/founder-members/${item.slug}`}
                        onClick={() => {
                          dispatch(changeNavState(49));
                          setShowMobileMenu(false);
                        }}
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            )}

            {/*...mobile Gallery...*/}
            <div
              className={`px-[10px] py-[10px] relative flex flex-col items-center ${
                activeNav === 20 &&
                "text-[#D4432F] border-2 border-t-0 border-l-0 border-r-0 border-b-[#D4432F]"
              }`}
            >
              <Link
                to={`/gallery`}
                onClick={() => setShowMobileMenu(false)}
                className="font-medium"
              >
                Gallery
              </Link>
            </div>
          </div>

          <div
            onClick={() => setShowMobileMenu(false)}
            className="cursor-pointer absolute top-[20px] right-[10px] w-[30px] h-[30px] rounded-full shadow-lg flex justify-center items-center bg-primary"
          >
            <RxCross2 className="text-xl text-white" />
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
