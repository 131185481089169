import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const initialState = {
  activeNav: parseInt(Cookies.get("activeNav")) || 0,
  isNoticePopupActuve: true,
  checkNavIndex: 0,
};

export const navSlice = createSlice({
  name: "navState",
  initialState,
  reducers: {
    changeNavState: (state, action) => {
      state.activeNav = action.payload;
      Cookies.set("activeNav", action.payload, { path: "/" });
    },
    changeNoticePopup: (state, action) => {
      state.isNoticePopupActuve = action.payload;
    },
    checkNavIndex: (state, action) => {
      state.navIndex = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {checkNavIndex, changeNavState, changeNoticePopup } = navSlice.actions;

export default navSlice.reducer;
