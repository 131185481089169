import React, { useState, useEffect, useRef } from 'react';

const NumberCounter = ({ targetNumber, duration, classname }) => {
  const [currentNumber, setCurrentNumber] = useState(0);
  const counterRef = useRef(null);

  // Calculate the increment value based on targetNumber and duration
  const increment = Math.ceil(targetNumber / (duration / 1000));

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Start the counter animation
            const startTime = performance.now();
            const updateNumber = () => {
              const elapsedTime = performance.now() - startTime;
              const newValue = Math.min(Math.floor((elapsedTime / duration) * targetNumber), targetNumber);
              setCurrentNumber(newValue);
              if (newValue < targetNumber) {
                requestAnimationFrame(updateNumber);
              }
            };
            updateNumber();

            // Stop observing once animation is complete
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.5 }
    );

    if (counterRef.current) {
      observer.observe(counterRef.current);
    }

    return () => {
      if (counterRef.current) {
        observer.unobserve(counterRef.current);
      }
    };
  }, [targetNumber, duration]);

  return (
    <>
      <p className={classname} ref={counterRef}>{currentNumber}+</p>
    </>
  );
};

export default NumberCounter;
