import React, { useEffect, useState } from "react";
import axiosBaseURL from "../utils/axios";
import { BoardMemberCard, Loader, PageBanner } from "../components";
import { tuLionsOffice } from "../assets";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

const FounderMembers = () => {
  let { slug } = useParams();

  const [loading, setLoading] = useState(false);
  const [members, setMembers] = useState([]);
  const [bottomMembers, setBottomMembers] = useState([]);
  const [second, setSecond] = useState([]);
  // Fetching Teams
  const fetchTeamMembers = async () => {
    setLoading(true);
    try {
      await axiosBaseURL.get(`/team-categories/${slug}`).then((res) => {
        if (res?.data?.data?.length > 0) {
          setMembers(res.data.data);
          setLoading(false);
          return;
        }
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTeamMembers();
  }, [slug]);

  useEffect(() => {
    if (members?.length > 0) {
      const belowData = members?.filter((item) => members.indexOf(item) !== 0);
      const secondDiv = belowData.slice(0, 3);
      setSecond(secondDiv);
      setBottomMembers(belowData.slice(3));
      console.log(belowData);
    }
  }, [members]);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          TU Lions Blood Transfusion and Reasearch Center - Founder Members
        </title>
        <meta name="description" content="" />
      </Helmet>

      <PageBanner
        title="Founder Members"
        overlay="bg-[#00539B]"
      />

      <div className="py-[30px] section-padding flex flex-col items-center ">
        <div className="w-full border-2 border-t-0 border-l-0 border-r-0 border-b-[#9A9898] pb-[10px]">
          <p className="text-[#00539B] font-bold text-lg ">
            T.U Lions Blood Transfusion And Research Centre
          </p>
          <p>Founder Members</p>
        </div>

        <div className="w-full mt-[50px]">
          {loading ? (
            <div className="w-full flex justify-center items-center">
              <Loader />
            </div>
          ) : members?.length > 0 ? (
            <div className="flex flex-col items-center lg:gap-[50px] gap-[30px] ">
              <div className="md:max-w-[344px] w-full">
                <BoardMemberCard
                  image={members[0].image}
                  name={members[0].name}
                  position={members[0].designation}
                />
              </div>
              <div className="flex items-center justify-around flex-wrap -mx-2 max-w-[1200px] w-full lg:gap-0 gap-[30px]">
                {second?.length > 0 &&
                  second?.map((item, index) => (
                    <div className="md:w-1/3 w-full md:px-2">
                      <BoardMemberCard
                        key={index}
                        image={item.image}
                        name={item.name}
                        position={item.designation}
                      />
                    </div>
                  ))}
              </div>
              <div className="w-full flex gap-[30px] flex-wrap lg:grid lg:grid-cols-4 lg:gap-4 justify-center">
                {bottomMembers?.map((item, index) => (
                  <BoardMemberCard
                    key={index}
                    image={item.image}
                    name={item.name}
                    position={item.designation}
                    className={`md;w-1/3 w-full`}
                  />
                ))}
              </div>
            </div>
          ) : (
            <div>
              <p>No Data Found</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FounderMembers;
