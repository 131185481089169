import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosBaseURL from "../utils/axios";
import { Loader, PageBanner, RecentnewsCard } from "../components";
import { author } from "../assets";
import { dateFormatter } from "../utils/dateFormatter";
import { Helmet } from "react-helmet";

const EventDetails = () => {
  let { slug } = useParams();

  const [loading, setLoading] = useState(false);
  const [newsDetails, setnewsDetails] = useState("");
  const [recentnews, setRecentnews] = useState([]);
  const [tagsArray, setTagsArray] = useState([]);
  // Fetching news Details
  const fetchnewsDetails = async (slug) => {
    setLoading(true);
    try {
      await axiosBaseURL.get(`/event/${slug}`).then((res) => {
        if (res?.data?.data?.event) {
          setnewsDetails(res?.data?.data?.event);
          setLoading(false);
          return;
        }
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // Fetch Recent newss
  const fetchRecentnews = async () => {
    setLoading(true);
    try {
      await axiosBaseURL.get(`/recent-blogs`).then((res) => {
        if (res?.data?.data?.length > 0) {
          setRecentnews(res.data.data);
          return;
        }
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (slug) {
      fetchnewsDetails(slug);
      fetchRecentnews();
    }
  }, [slug]);

  useEffect(() => {
    if (newsDetails.tags) {
      const arrayOfTags = newsDetails.tags?.split(",");
      if (arrayOfTags?.length > 0) {
        setTagsArray(arrayOfTags);
      }
    }
  }, [newsDetails]);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{newsDetails?.title ? newsDetails?.title : ""}</title>
        <meta
          name="description"
          content={
            newsDetails?.meta?.meta_description
              ? newsDetails?.meta?.meta_description
              : ""
          }
        />
      </Helmet>
      <PageBanner title={newsDetails?.title} />
      <div className="section-padding">
        <div className="max-w-[900px] mx-auto">
          <div className="py-[30px] lg:w-[800px]">
            {loading ? (
              <div className="w-full flex justify-center items-center">
                <Loader />
              </div>
            ) : newsDetails ? (
              <div className="mb-[30px] text-left">
                <h1 className="font-bold text-2xl text-primary mb-2">
                  {newsDetails?.title}
                </h1>
                {/*....Author Details....*/}
                <div className="w-max flex items-center mb-4">
                  <img src={author} alt="authorImage" />
                  <div className="text-start">
                    <p className="text-[#505050] font-medium">
                      {newsDetails?.author}
                    </p>
                    <p className="text-[#777777] text-[13px]">
                      {dateFormatter(newsDetails?.date)}
                    </p>
                  </div>
                </div>

                {/*....Main Image....*/}
                {newsDetails?.image && (
                  <div className="mt-[20px]">
                    <img
                      src={newsDetails?.image}
                      className="w-full lg:w-[100%] h-[300px] object-contain"
                      alt="mainImage"
                    />
                  </div>
                )}

                {/*....Details....*/}
                {newsDetails?.details && (
                  <div
                    className="news-content"
                    dangerouslySetInnerHTML={{
                      __html: newsDetails?.details,
                    }}
                  />
                )}

                {/*....Tags....*/}
                <div className="w-[200px] px-[5px] flex items-center gap-[10px] flex-wrap mt-[30px]">
                  {tagsArray?.length > 0 &&
                    tagsArray?.map((item, index) => (
                      <div
                        key={index}
                        className="w-max rounded-full px-[10px] py-[2px] border-2 border-slate-300 text-[#777777]"
                      >
                        {item}
                      </div>
                    ))}
                </div>
              </div>
            ) : (
              <div>
                <p>No Data Found</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventDetails;
