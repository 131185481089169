import React from "react";
import { PageBanner, NumberCounter, Loader } from "../components";
import { Helmet } from "react-helmet";
import { blood } from "../assets";
import { HiUserGroup } from "react-icons/hi";
import { Navigation, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
// import Swiper and modules styles
import "swiper/css";
import "swiper/css/navigation";
import { useState } from "react";
import axiosBaseURL from "../utils/axios";
import { useEffect } from "react";
import parse from "html-react-parser";

const AllMembers = () => {
  const [loading, setLoading] = useState(false);
  const [partner, setPartner] = useState("");
  const [founder, setFounder] = useState("");

  const fetchPartner = async () => {
    try {
      await axiosBaseURL.get(`/partners`).then((res) => {
        if (res?.data?.data) {
          setPartner(res?.data?.data);
          return;
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  const fetchFounder = async () => {
    try {
      await axiosBaseURL.get(`/founding-member`).then((res) => {
        if (res?.data) {
          setFounder(res?.data);
          return;
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchPartner();
    fetchFounder();
  }, []);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>All Founding Members</title>
      </Helmet>
      <PageBanner
        title="All Founding Members"
        overlay="bg-[#00539B]"
      />
      <div className="member-section pt-16">
        {founder && (
          <>
            <div className="member-desc text-left pb-20 section-padding">
              <div className="max-w-[1400px] mx-auto">
                <h1 className="heading !text-3xl mb-4">
                  {founder?.founding_title}
                </h1>
                <div className="flex gap-8 flex-row lg:flex-nowrap flex-wrap">
                  <div className="member-para leading-8 text-justify">
                    {founder?.founding_details &&
                      parse(founder?.founding_details)}
                  </div>
                  <figure className="lg:max-w-[400px] max-w-full w-full rounded-xl overflow-hidden flex-none h-[400px] sticky top-0">
                    <img
                      src={founder?.founding_img}
                      alt={founder?.founding_title}
                      className="w-full h-full object-center object-cover"
                    />
                  </figure>
                </div>
              </div>
            </div>
            <div className="member-list bg-primary text-left lg:py-24 py-16 text-white  section-padding">
              <div className="max-w-[1400px] mx-auto px-7">
                <ul className="flex justify-between lg:flex-nowrap flex-wrap -mx-7 gap-y-8">
                  <li className="lg:w-1/4 md:w-1/2 w-full px-7">
                    <article className="relative pl-11">
                      <HiUserGroup className="absolute top-0 left-0 text-3xl" />
                      <h4 className=" text-xl mb-4">
                        {founder?.counter_data?.title_first}
                      </h4>
                      <NumberCounter
                        classname="font-bold text-5xl"
                        targetNumber={founder?.counter_data?.value_first}
                        duration={10000}
                      />
                    </article>
                  </li>
                  <li className="lg:w-1/4 md:w-1/2 w-full px-7">
                    <article className="relative pl-11">
                      <HiUserGroup className="absolute top-0 left-0 text-3xl" />
                      <h4 className=" text-xl mb-4">
                        {founder?.counter_data?.title_second}
                      </h4>
                      <NumberCounter
                        classname="font-bold text-5xl"
                        targetNumber={founder?.counter_data?.value_second}
                        duration={10000}
                      />
                    </article>
                  </li>
                  <li className="lg:w-1/4 md:w-1/2 w-full px-7">
                    <article className="relative pl-11">
                      <HiUserGroup className="absolute top-0 left-0 text-3xl" />
                      <h4 className=" text-xl mb-4">
                        {founder?.counter_data?.title_third}
                      </h4>
                      <NumberCounter
                        classname="font-bold text-5xl"
                        targetNumber={founder?.counter_data?.value_third}
                        duration={10000}
                      />
                    </article>
                  </li>
                  <li className="lg:w-1/4 md:w-1/2 w-full px-7">
                    <article className="relative pl-11">
                      <HiUserGroup className="absolute top-0 left-0 text-3xl" />
                      <h4 className=" text-xl mb-4">
                        {founder?.counter_data?.title_fourth}
                      </h4>
                      <NumberCounter
                        classname="font-bold text-5xl"
                        targetNumber={founder?.counter_data?.value_fourth}
                        duration={10000}
                      />
                    </article>
                  </li>
                </ul>
              </div>
            </div>
          </>
        )}
        {partner?.length > 0 && (
          <div className="member-partners text-left py-20  section-padding">
            <div className="max-w-[1400px] mx-auto">
              <div className="heading-wrapper">
                <h2 className="heading !text-3xl mb-10">Notable Partners</h2>
              </div>
              <div className="partner-slider">
                <Swiper
                  spaceBetween={30}
                  slidesPerView={2}
                  breakpoints={{
                    768: {
                      slidesPerView: 3,
                    },
                    1024: {
                      slidesPerView: 5,
                    },
                  }}
                  autoplay={{
                    delay: 3000,
                    pauseOnMouseEnter: true,
                  }}
                  navigation={true}
                  modules={[Autoplay, Navigation]}
                  className="mySwiper"
                >
                  {partner?.map((item, index) => (
                    <SwiperSlide key={index} className="flex justify-center">
                      <figure className="w-[140px] h-[140px] p-5 mx-auto">
                        <img
                          src={item?.image}
                          alt={item?.title}
                          className="w-full h-full object-contain object-center"
                        />
                      </figure>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AllMembers;
