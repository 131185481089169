import React from "react";
import { Loader, PageBanner } from "../components";
import { blood, bloodDonateImg } from "../assets";
import { Helmet } from "react-helmet";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { GiWaterDrop } from "react-icons/gi";
import { useState } from "react";
import axiosBaseURL from "../utils/axios";
import { useEffect } from "react";
import parse from "html-react-parser";

const BloodCenter = () => {
  const [loading, setLoading] = useState(false);
  const [bloodData, setBloodData] = useState("");
  const [bloodInfo, setBloodInfo] = useState();

  const fetchBloodData = async () => {
    setLoading(true);

    try {
      await axiosBaseURL.get(`/blood-groups`).then((res) => {
        if (res?.data?.data) {
          setBloodData(res?.data?.data);
          setLoading(false);
          return;
        }
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const fetchBloodInfo = async () => {
    setLoading(true);
    try {
      await axiosBaseURL.get(`/blood-group-info-page`).then((res) => {
        if (res?.data?.data) {
          setBloodInfo(res?.data?.data);
          setLoading(false);

          // console.log(bloodInfo);
          return;
        }
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchBloodData();
    fetchBloodInfo();
  }, []);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Blood Group Info</title>
      </Helmet>
      <PageBanner overlay="bg-[#00539B]" />

      <div className="blood-section py-16 text-left section-padding">
        <div className="max-w-[1400px] mx-auto">
          <div className="blood-desc ">
            <h1 className="heading !text-3xl mb-4">Blood Group Info</h1>
            <div className="flex gap-8 flex-row">
              <div className="blood-para leading-8 text-justify">
                {bloodInfo?.blood_group_info &&
                  parse(bloodInfo?.blood_group_info)}
              </div>
              <figure className="hidden lg:block max-w-[400px] h-[320px] w-full rounded-xl overflow-hidden flex-none sticky top-0">
                <img
                  src={bloodInfo?.blood_info}
                  alt="blood donation"
                  className="w-full h-full object-center object-cover"
                />
              </figure>
            </div>
          </div>
          {loading ? (
            <div className="w-full flex justify-center items-center">
              <Loader />
            </div>
          ) : (
            bloodData && (
              <div className="blood-tabs py-16">
                <div className="max-w-[1400px] mx-auto xl:p-10 p-5 border border-solid border-gray-300 rounded-xl">
                  <Tabs>
                    <TabList className="flex gap-3 mb-6 flex-wrap">
                      {bloodData?.map((item, index) => (
                        <React.Fragment key={index}>
                          <Tab>
                            <div className="blood-name relative">
                              <h6>
                                <GiWaterDrop />
                                {item?.name}
                              </h6>
                            </div>
                          </Tab>
                        </React.Fragment>
                      ))}
                    </TabList>

                    {bloodData?.map((item, index) => (
                      <TabPanel key={index}>
                        <div className="blood-details flex gap-8 lg:flex-nowrap flex-wrap">
                          <figure className="max-w-[300px] w-full flex-none">
                            <img
                              src={
                                item?.grp_image
                                  ? item?.grp_image
                                  : bloodDonateImg
                              }
                              alt="blood donation"
                              className="w-full lg:h-full h-[300px]  object-center object-cover"
                            />
                          </figure>
                          <article className="leading-8 text-justify">
                            {item?.description && parse(item?.description)}
                          </article>
                        </div>
                      </TabPanel>
                    ))}
                  </Tabs>
                </div>
              </div>
            )
          )}

          <div className="blood-type">
            <div className="grid lg:grid-cols-2 grid-cols-1 lg:gap-7 gap-14">
              <div className="col-span-1">
                <div className="type-wrapper">
                  <div className="heading-wrapper !mb-10">
                    <h4 className="heading mb-2 !text-2xl">
                      How Rare is my blood type
                    </h4>
                    <p className="leading-8">
                      “The rarest blood type is the type that's not available
                      when YOU need it!”
                    </p>
                  </div>
                  <figure className="lg:h-[400px] h-auto">
                    <img src={bloodInfo?.blood_type_img} alt="BloodRaretype" className="h-full object-contain" />
                  </figure>
                </div>
              </div>
              <div className="col-span-1">
                <div className="type-wrapper">
                  <div className="heading-wrapper !mb-10">
                    <h4 className="heading mb-2 !text-2xl">Blood Donation</h4>
                    <div className="leading-8">
                      {bloodInfo?.blood_donation &&
                        parse(bloodInfo?.blood_donation)}
                    </div>
                  </div>
                  <figure className="h-[400px]">
                    <img
                      src={bloodInfo?.blood_donation_img} className="h-full object-contain"
                      alt="Blood Dontation"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="flex flex-col items-center gap-[30px] px-[200px] text-start text-justify mt-[30px]">
        <p>
          As you probably know, we all have a blood type. But do you know what
          makes each type unique?While several blood group systems are used to
          type your blood, ABO is the most important. Your ABO type is based on
          the presence or absence of two antigens (A and B) on the surface of
          red blood cells. There are four ABO types: A, B, AB and O.Your blood
          type is also determined by Rh status: Rh+ or Rh-. That leaves us with
          eight possible blood types:
        </p>

        <img src={bloodTypes} className="w-" alt="bloodTypes" />

        <p>
          Each of these eight types is unique. Blood types determine who you can
          give blood to and receive blood from. Which type of donation is best
          for you is determined by your blood type, as well as patient need.
          Read more to find out what makes your blood type special:
        </p>

        <div className="flex flex-col gap-[30px]">
          <div className="flex justify-between items-center gap-[50px]">
            <img src={bloodDonateImg} alt="bloodImage" />
            <p>
              Is one of the rarest blood types — only 6.6% of the US population
              has type O- Is a universal donor, meaning anyone can receive type
              O- blood products Is an ideal whole blood or double red blood cell
              donor. Your blood type is constantly needed, so please come in and
              donate as frequently as… Is one of the rarest blood types — only
              6.6% of the US population has type O- Is a universal donor,
              meaning anyone can receive type O- blood products Is an ideal
              whole blood or double red blood cell donor. Your blood type is
              constantly needed, so please come in and donate as frequently as
              possible Donation type is based on what is best for patients and
              the donor. To learn more about what type of donation is best for
              you, give us a call at 888-723-7831.
            </p>
          </div>

          <div className="flex justify-between items-center gap-[50px]">
            <img src={bloodDonateImg} alt="bloodImage" />
            <p>
              Is one of the most common and, consequently, most transfused blood
              types — 37.4% of the US population has type O+ Can give blood
              products to all Rh+ types (A+, B+, O+ and AB+) and receive all O
              types Is an ideal whole blood or double red blood cell donor
              Donation type is based on… Is one of the most common and,
              consequently, most transfused blood types — 37.4% of the US
              population has type O+ Can give blood products to all Rh+ types
              (A+, B+, O+ and AB+) and receive all O types Is an ideal whole
              blood or double red blood cell donor Donation type is based on
              what is best for patients and the donor. To learn more about what
              type of donation is best for you, give us a call at 888-723-7831.
            </p>
          </div>
        </div>

        <div className="flex flex-col gap-[30px]">
          <div className="flex justify-between items-center gap-[50px]">
            <p>
              Is one of the most common and, consequently, most transfused blood
              types — 35.7% of the US population has type A+ Can give blood
              products to types A+ and AB+ and receive all A and O types Is an
              ideal whole blood, double red blood cell or apheresis platelet
              donor Donation type is based on… Is one of the most common and,
              consequently, most transfused blood types — 35.7% of the US
              population has type A+ Can give blood products to types A+ and AB+
              and receive all A and O types Is an ideal whole blood, double red
              blood cell or apheresis platelet donor Donation type is based on
              what is best for patients and the donor. To learn more about what
              type of donation is best for you, give us a call at 888-723-7831.
            </p>
            <img src={bloodDonateImg} alt="bloodImage" />
          </div>

          <div className="flex justify-between items-center gap-[50px]">
            <p>
              Is one of the rarest blood types — only 6.3% of the US population
              has type A- Can give blood products to all A and AB types, and
              receive types A- and O- Is an ideal whole blood, double red blood
              cell or apheresis platelet donor Donation type is based on what is
              best for… Is one of the rarest blood types — only 6.3% of the US
              population has type A- Can give blood products to all A and AB
              types, and receive types A- and O- Is an ideal whole blood, double
              red blood cell or apheresis platelet donor Donation type is based
              on what is best for patients and the donor. To learn more about
              what type of donation is best for you, give us a call at
              888-723-7831.
            </p>
            <img src={bloodDonateImg} alt="bloodImage" />
          </div>
        </div>

        <div className="flex flex-col gap-[30px]">
          <div className="flex justify-between items-center gap-[50px]">
            <img src={bloodDonateImg} alt="bloodImage" />
            <p>
              Is one of the rarest blood types — only 1.5% of the US population
              has type B- Can give blood products to all B and AB types and
              receive types O- and B- Is an ideal whole blood, double red blood
              cell or apheresis platelet donor Donation type is based on what is
              best for… Is one of the rarest blood types — only 1.5% of the US
              population has type B- Can give blood products to all B and AB
              types and receive types O- and B- Is an ideal whole blood, double
              red blood cell or apheresis platelet donor Donation type is based
              on what is best for patients and the donor. To learn more about
              what type of donation is best for you, give us a call at
              888-723-7831. Read More
            </p>
          </div>

          <div className="flex justify-between items-center gap-[50px]">
            <img src={bloodDonateImg} alt="bloodImage" />
            <p>
              Is one of the rarest blood types — only 8.5% of the US population
              has type B+ Can give blood products to types B+ and AB+, and
              receive all B and O types Is an ideal whole blood, double red
              blood cell or apheresis platelet donor Donation type is based on
              what is best for… Is one of the rarest blood types — only 8.5% of
              the US population has type B+ Can give blood products to types B+
              and AB+, and receive all B and O types Is an ideal whole blood,
              double red blood cell or apheresis platelet donor Donation type is
              based on what is best for patients and the donor. To learn more
              about what type of donation is best for you, give us a call at
              888-723-7831.
            </p>
          </div>
        </div>

        <div className="flex flex-col gap-[30px]">
          <div className="flex justify-between items-center gap-[50px]">
            <p>
              Is one of the rarest blood types — only 3.4% of the US population
              has type AB+ Is a universal recipient, meaning you can receive
              blood products of any blood type Is a universal plasma donor,
              meaning anyone with any blood type can receive your plasma Is an
              ideal whole blood or plasma donor Donation… Is one of the rarest
              blood types — only 3.4% of the US population has type AB+ Is a
              universal recipient, meaning you can receive blood products of any
              blood type Is a universal plasma donor, meaning anyone with any
              blood type can receive your plasma Is an ideal whole blood or
              plasma donor Donation type is based on what is best for patients
              and the donor. To learn more about what type of donation is best
              for you, give us a call at 888-723-7831.
            </p>
            <img src={bloodDonateImg} alt="bloodImage" />
          </div>

          <div className="flex justify-between items-center gap-[50px]">
            <p>
              Is the rarest blood type — only 0.6% of the US population has type
              AB- Is a universal plasma donor, meaning anyone can receive AB-
              plasma Is an ideal whole blood, double red blood cell or plasma
              donor Donation type is based on what is best for patients and the
              donor. To learn more about… Is the rarest blood type — only 0.6%
              of the US population has type AB- Is a universal plasma donor,
              meaning anyone can receive AB- plasma Is an ideal whole blood,
              double red blood cell or plasma donor Donation type is based on
              what is best for patients and the donor. To learn more about what
              type of donation is best for you, give us a call at 888-723-7831.
            </p>
            <img src={bloodDonateImg} alt="bloodImage" />
          </div>
        </div>

        <div className="w-full flex flex-col gap-[30px]">
          <p className="text-[#00539B] font-bold text-lg ">
            How Rare is my blood type
          </p>

          <p>
            “The rarest blood type is the type that’s not available when YOU
            need it!”
          </p>

          <img src={rareBloodTypes} className="w-max" alt="rare-Blood-types" />
        </div>

        <div className="w-full flex flex-col gap-[30px]">
          <p className="text-[#00539B] font-bold text-lg ">Blood Donation</p>

          <p>
            A Blood type is a classification of blood based on the presence or
            absence of antigene on the surface of Red blood cells.Human blood is
            mainly divided in to four main bloods types i.e A,B,AB and O and is
            further divided into Rh- and Rh+.
          </p>

          <img src={bloodDonorType} className="w-max" alt="rare-Blood-types" />
        </div>
      </div> */}
    </div>
  );
};

export default BloodCenter;
