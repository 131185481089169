import React, { useEffect, useState } from "react";
import axiosBaseURL from "../utils/axios";
import { Loader, PageBanner } from "../components";
import BlogCard from "../components/BlogCard";
import { Helmet } from "react-helmet";

const Blogs = () => {
  const [loading, setLoading] = useState(false);
  const [blogs, setBlogs] = useState([]);

  const fetchBlogs = async () => {
    setLoading(true);
    try {
      await axiosBaseURL.get("/blogs").then((res) => {
        if (res?.data?.data?.length > 0) {
          setBlogs(res?.data?.data);
          setLoading(false);
          return;
        }
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  return (
    <div className="pb-[100px]">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Blogs</title>
      </Helmet>
      <PageBanner title="Blogs" />
<div className="section-padding">
      <div className="mx-auto max-w-[1400px] py-16">
        <h1 className="font-roboto font-extrabold text-2xl text-center text-[#00539B] mb-4">
          Blogs
        </h1>

        <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 gap-6">
          {loading ? (
            <div className="w-full flex justify-center items-center text-center">
              <Loader />
            </div>
          ) : blogs?.length > 0 ? (
            blogs.map((item, index) => (
              <BlogCard
                key={index}
                image={item.image}
                title={item.title}
                description={item.excerpt}
                tags={item.tags}
                slug={item.slug}
              />
            ))
          ) : (
            <div>No Data Found</div>
          )}
        </div>
      </div>
      </div>
    </div>
  );
};

export default Blogs;
